import * as React from 'react'
import Box from '@mui/material/Box'
// import Button from '@mui/material/Button'
// import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
// import Paper from '@mui/material/Paper'
// import Divider from '@mui/material/Divider'
import Link from '@mui/material/Link'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'

import data from '../../../data'

export const membershipOptions = [
  {
    name: 'Membership',
    highlight: 'Save $20 every month',
    description: (
      <Box>
        <Typography variant="body1">The standard membership option at The Black Dog Jiu-Jitsu Company.</Typography>
        <List sx={{
          listStyleType: 'disc',
          pl: 2,
          '& .MuiListItem-root': {
            display: 'list-item'
          }
        }}>
          <ListItem>
            <ListItemText>Access to all weekly programming</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>Full priviledges of membership, <Link href="#discounts" color="secondary.dark">all discounts available</Link>.</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>3-month commitment ($477.00 billed quarterly)</ListItemText>
          </ListItem>
        </List>
      </Box>
    ),
    price: '$159.00 / month',
    link: data.memberPortal.enrollmentUrl
  },
  {
    name: 'One-year Membership',
    highlight: '30% off all year',
    description: (
      <Box>
        <Typography variant="body1">Save on monthly membership dues by committing to one year upfront.</Typography>
        <List sx={{
          listStyleType: 'disc',
          pl: 2,
          '& .MuiListItem-root': {
            display: 'list-item'
          }
        }}>
          <ListItem>
            <ListItemText>Standard Membership with access to all weekly programming</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText><Link href="#discounts" color="secondary.dark">All discounts available</Link>.</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>One upfront charge paid annually &mdash; don't bother with payments throughout the year.</ListItemText>
          </ListItem>
        </List>
      </Box>
    ),
    price: '$1499 / year',
    link: data.memberPortal.oneYearEnrollmentUrl
  },
  {
    name: 'Month-to-Month Membership',
    description: (
      <Box>
        <Typography variant="body1">A flexible option for membership at The Black Dog Jiu-Jitsu Company.</Typography>
        <List sx={{
          listStyleType: 'disc',
          pl: 2,
          '& .MuiListItem-root': {
            display: 'list-item'
          }
        }}>
          <ListItem>
            <ListItemText>All the benefits of membership</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>No commitment term</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>A good option for those who need a little more flexibility.</ListItemText>
          </ListItem>
        </List>
      </Box>
    ),
    price: '$179.00 / month',
    link: data.memberPortal.monthToMonth
  },
  {
    name: 'Young Adult Membership',
    description: (
      <Box>
        <Typography variant="body1">Young athletes excell at The Black Dog Jiu-Jitsu Company.</Typography>
        <List sx={{
          listStyleType: 'disc',
          pl: 2,
          '& .MuiListItem-root': {
            display: 'list-item'
          }
        }}>
          <ListItem>
            <ListItemText>Young adults ages {data.ya.age.min} - {data.ya.age.max}</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>All weekly young adult coursework.  For more information please see the <Link href="/ya" color="secondary.dark">Young Adult page</Link>.</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>No commitment term</ListItemText>
          </ListItem>
        </List>
      </Box>
    ),
    price: '$129.00 / month',
    link: data.memberPortal.yaEnrollmentUrl
  }
]

export const nonMembershipOptions = [
  {
    name: 'BJJ 101',
    description: (
      <Box mb={2}>
        <Typography variant="body1">Learn Jiu-Jitsu in a structured class environment - Come join us on the mats!  For more information please see the <Link href="/bjj101" color="secondary.dark">BJJ 101 page</Link>.</Typography>
      </Box>
    ),
    price: '$69.00 / month',
    link: data.memberPortal.bjj101EnrollmentUrl,
    buttonText: 'Register'
  },
  {
    name: 'One-Week Limited Membership',
    description: (
      <Box mb={2}>
        <Typography variant="body1">Train for a week at The Black Dog Jiu-Jitsu Company for just $1.  This option is ideal for people who want to check out the gym before becoming a member.</Typography>
      </Box>
    ),
    price: '$1.00',
    link: data.memberPortal.oneWeekUrl,
    buttonText: 'Register'
  },
  {
    name: 'Day Pass',
    description: (
      <Box mb={2}>
        <Typography variant="body1">Train for the day at The Black Dog Jiu-Jitsu.</Typography>
      </Box>
    ),
    price: '$20.00',
    link: data.memberPortal.dayPassUrl,
    buttonText: 'Register'
  },
  {
    name: '10-Session Punchcard',
    highlight: '25% off',
    description: (
      <Box mb={2}>
        <Typography variant="body1">Want to train but you feel like you need some added flexibility?  Use a punch card. 10 sessions that don't expire.</Typography>
      </Box>
    ),
    price: '$150.00',
    link: data.memberPortal.tenSessionPunchcard,
    buttonText: 'Buy now'
  },
  {
    name: '5-Session Punchcard',
    description: (
      <Box mb={2}>
        <Typography variant="body1">Want to train but you feel like you need some added flexibility?  Use a punch card.  5 sessions that don't expire.</Typography>
      </Box>
    ),
    price: '$100.00',
    link: data.memberPortal.fiveSessionPunchcard,
    buttonText: 'Buy now'
  }
]
