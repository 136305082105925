import React from 'react'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import ReactMarkdown from 'react-markdown'

import termsOfUse from '../../content/terms-of-use'

function TermsOfUse () {
  return (
    <Box id="termsofuse" className="marginContainer creme">
      <Container maxWidth="xl" disableGutters={true}>
        <Grid container>
          <Grid item md>
            <Typography variant="h3">Terms of Use</Typography>
            <ReactMarkdown>
              {termsOfUse}
            </ReactMarkdown>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default TermsOfUse
