import useMediaQuery from '@mui/material/useMediaQuery'
import LocationSmall from './LocationSmall'
import LocationLarge from './LocationLarge'

export default function Location () {
  const smallScreen = !useMediaQuery('(min-width:600px)')

  return (smallScreen)
    ? (<LocationSmall />)
    : (<LocationLarge />)
}
