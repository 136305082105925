import image1 from '../../../assets/shortArchiveImages/BlackDogJJ_01.jpg'
import image2 from '../../../assets/shortArchiveImages/BlackDogJJ_04.jpg'
import image3 from '../../../assets/shortArchiveImages/BlackDogJJ_10.jpg'
import image4 from '../../../assets/shortArchiveImages/BlackDogJJ_18.jpg'
import image5 from '../../../assets/shortArchiveImages/BlackDogJJ_22.jpg'
import image6 from '../../../assets/shortArchiveImages/BlackDogJJ_45.jpg'
import image7 from '../../../assets/shortArchiveImages/BlackDogJJ_50.jpg'
import image8 from '../../../assets/shortArchiveImages/blackonblack.jpg'
import image9 from '../../../assets/shortArchiveImages/front.jpg'

const galleryImages = [
  {
    img: image1
  },
  {
    img: image2
  },
  {
    img: image8
  },
  {
    img: image3
  },
  {
    img: image4
  },
  {
    img: image9
  },
  {
    img: image5
  },
  {
    img: image6
  },
  {
    img: image7
  }
]

export default galleryImages
