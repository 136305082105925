import * as React from 'react'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'

import SlideIn from '../../utils/SlideIn'

export default function Discounts () {
  return (
    <Box id="discounts" className="marginContainerTall creme">
      <SlideIn>
        <Container maxWidth="xl" disableGutters={true}>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 'bold',
              textTransform: 'uppercase'
            }}
            mb={1}
          >Discounts</Typography>
          <Typography variant="body1" mb={3}>Discounts are offered to members in recognition of their contribution to the gym. The following discount codes are available for use with any of qualifying memberships*.</Typography>
          <Box my="2">
            <DiscountsTable />
          </Box>
          <Box sx={{ width: '100%', textAlign: { xs: 'left', sm: 'right' } }} my={2}>
            <Typography variant="body1">To use a discount, apply the code during the checkout process in the online membership enrollment form.</Typography>
          </Box>
        </Container>
      </SlideIn>
    </Box>
  )
}

const discounts = [
  {
    name: 'Black Belt',
    discount: '30% off of monthly membership fees',
    description: 'Members who hold the rank of Black Belt in Brazilian Jiu-Jitsu qualify for a 30% discount at The Black Dog Jiu-Jitsu Company.  Black Belts contribute to the gym immesurably and this discount is a token of our appreciation for that contribution.',
    code: 'BLACKBELT'
  },
  {
    name: 'Brown Belt',
    discount: '20% off of monthly membership fees',
    description: 'Members who hold the rank of Brown Belt in Brazilian Jiu-Jitsu qualify for a 20% discount at The Black Dog Jiu-Jitsu Company.  Similar to the discount offered to Black Belts, we recognize the contribution of Brown Belts to the gym.',
    code: 'BROWNBELT'
  },
  {
    name: 'Founders Discount',
    discount: '40% off of monthly membership fees',
    description: 'This discount code has been issued in very limited supply in order to recognize the early contributors and members at The Black Dog Jiu-Jitsu Company.',
    code: 'FOUNDER2023'
  },
  {
    name: 'First Responders',
    discount: '20% off of monthly membership fees',
    description: 'In recognition of the importance of our first responders and requirements of their service, The Black Dog Jiu-Jitsu Company offers a 20% tuition discount for all First Responders.',
    code: 'FIRSTRESPONDER'
  },
  {
    name: 'Military Service',
    discount: '20% off of monthly membership fees',
    description: 'The Black Dog Jiu-Jitsu Company offers a 20% discount to all current and former members of the US Armed Services in recognition of their service to the country.',
    code: 'ARMEDSERVICES'
  },
  {
    name: 'Household Discount',
    discount: '20% off of monthly membership fees for additional members of a household',
    description: 'You live together, you train together.  So amazing.  Additional parties of a household receive a 20% discount on membership at The Black Dog.',
    code: 'TRAINTOGETHER'
  }

]

function DiscountsTable () {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="right">Discount</TableCell>
            <TableCell align="right">Description</TableCell>
            <TableCell align="right">Code</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {discounts.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <Typography variant="body1">
                  {row.name}
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="body1">
                  {row.discount}
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="body1">
                  {row.description}
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="body1">
                  {row.code}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
