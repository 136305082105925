import * as React from 'react'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'

import MailingList from '../../MailingList'
import Hero from './Hero'
import SocialMedia from '../../SocialMedia'
import Enrollment from './Enrollment'
import Gallery from './Gallery'
import Curriculum from './Curriculum'
import WhatYouWillNeed from './WhatYouWillNeed'
import CheckTheFAQs from './CheckTheFAQs'

function BJJ101 () {
  return (
    <Box>
      <Hero />
      <Enrollment />
      <Gallery />
      <SocialMedia />
      <Curriculum />
      <Divider sx={{ borderColor: '#cccccc' }} />
      <WhatYouWillNeed />
      <CheckTheFAQs />
      <MailingList />
    </Box>
  )
}

export default BJJ101
