import React from 'react'
import Box from '@mui/material/Box'

import Hero from './Hero'
import MembershipOptionsPanel from './MembershipOptions'
import NonMemershipOptionsPanel from './NonMembershipOptions'
import Questions from './Questions'
import Discounts from './Discounts'
import Divider from './Divider'

export default function Welcome () {
  return (
    <Box className="creme">
      <Hero />
      <MembershipOptionsPanel />
      <Divider />
      <NonMemershipOptionsPanel />
      <Questions />
      <Discounts />
    </Box>
  )
}
