import React /*, { useRef } */ from 'react'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
// import 'add-to-calendar-button/assets/css/atcb.css'
// import { atcb_action } from 'add-to-calendar-button'
import data from '../../../data'

export default function Enrollment () {
  return (
    <Box id="enrollment-reminder" className="creme" sx={{ position: 'relative', boxShadow: 2 }}>
      <Box className="marginContainer">
        <Container maxWidth="xl" disableGutters={true}>
          <Grid container>
            <Grid item xs={12}>
              <Box>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={8}>
                    <Typography variant="h5" color="primary" sx={{ textTransform: 'uppercase' }}>Enrollment Open Now</Typography>
                    <Typography variant="body1" color="primary">Young Adult enrollment is open, but class sizes are limited.  Join The Black Dog Jiu-Jitsu Company today and claim your spot on the team.</Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box sx={{
                      display: 'flex',
                      justifyContent: { xs: 'flex-start', md: 'center' },
                      alignItems: { xs: 'left', md: 'center' },
                      height: '100%'
                    }}>
                      <Box mr={2}>
                        <Button variant="contained" href={data.memberPortal.yaEnrollmentUrl} color="primary">Enroll Now</Button>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  )
}
