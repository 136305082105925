import React from 'react'
import Box from '@mui/material/Box'

import Hero from './Hero'
import Gear from './Gear'
import MemberPortal from './MemberPortal'
import NextSteps from './NextSteps'
import SocialMedia from '../../SocialMedia'

export default function Welcome () {
  return (
    <Box id="images" className="creme">
      <Hero />
      <MemberPortal />
      <SocialMedia />
      <NextSteps />
      <Gear />
    </Box>
  )
}
