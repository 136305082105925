import * as React from 'react'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'

import TwoColumnHero from '../../TwoColumnHero'
import Image2 from '../../../assets/standing1.jpg'
import data from '../../../data'

export default function Hero () {
  return (
    <TwoColumnHero
      title="Rules"
      image={Image2}
    >
      <Typography variant="body1" color="secondary" mb={1}>
        We ask that you observe these rules while training at The Black Dog Jiu-Jitsu Company.  These are not exhaustive lists, but they do provide some necessary guidance regarding general decorum while at the gym as well as rules concerning submissions and techniques as we train.
      </Typography>
      <Typography variant="body1" color="secondary" mb={1}>
        If you are unclear on any of the rules and guidance below, please reach out to us with questions either in-person or by <Link color="secondary.dark" href={`mailto:${data.company.email.info}`}>email</Link>.
      </Typography>
      <Typography variant="body1" color="secondary" mb={1}>
        Thank you for your cooperation.
      </Typography>
    </TwoColumnHero>
  )
}
