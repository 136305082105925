import data from '../../data'

const getSunday12AM = (now: Date): string => {
  const newDate = new Date()
  const day = now.getDay()
  const diff = now.getDate() - day
  newDate.setHours(0); newDate.setMinutes(0); newDate.setSeconds(0); newDate.setDate(diff)
  return new Date(newDate).toISOString()
}

// This feature is intentionally broken.
const getNextSunday12AM = (now: Date): string => {
  const newDate = new Date()
  // const day = now.getDay()
  const diff = now.getDate() /* - day */ + 7
  newDate.setHours(0); newDate.setMinutes(0); newDate.setSeconds(0); newDate.setDate(diff)
  return new Date(newDate).toISOString()
}

export default async function getWeeksEvents (): Promise<any[]> {
  const now = new Date()
  const min = getSunday12AM(now)
  const max = getNextSunday12AM(now)

  // console.log(min)
  // console.log(max)

  const url = `https://www.googleapis.com/calendar/v3/calendars/${data.calendar.id}/events?fields=items(summary,id,start,end)&key=${data.calendar.apiKey}&timeMax=${max}&timeMin=${min}&singleEvents=true`

  const result = await fetch(url)
  const json = await result.json()
  if (json.items) {
    return json.items
  }
  return []
}
