import React /*, { useRef } */ from 'react'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import 'add-to-calendar-button/assets/css/atcb.css'
// import { atcb_action } from 'add-to-calendar-button'
import data from '../../../data'

export default function Enrollment () {
  // const startDate = 'December 4th, 2023'
  // const addToCalendarButtonRef = useRef(null)

  /*
  const addToCalendarClickHandler = (e: any) => {
    e.preventDefault()
    if (addToCalendarButtonRef.current !== null) {
      atcb_action({
        name: 'BJJ 101',
        description: 'BJJ 101 at The Black Dog Jiu-Jitsu Company',
        startDate: '2023-12-04',
        endDate: '2023-12-04',
        startTime: '17:00',
        endTime: '18:00',
        location: 'The Black Dog Jiu-Jitsu Company',
        label: 'Add to Calendar',
        options: [
          'Apple',
          'Google',
          'iCal',
          'Microsoft365',
          'Outlook.com',
          'Yahoo'
        ],
        timeZone: 'America/Los_Angeles',
        iCalFileName: 'Reminder-Event'
      }, addToCalendarButtonRef.current)
    }
  }
  */

  return (
    <Box id="enrollment-reminder" className="creme" sx={{ position: 'relative', boxShadow: 2 }}>
      <Box className="marginContainer">
        <Container maxWidth="xl" disableGutters={true}>
          <Grid container>
            <Grid item xs={12}>
              <Box>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={8}>
                    <Typography variant="h5" color="primary" sx={{ textTransform: 'uppercase' }}>Enrollment Now Open</Typography>
                    <Typography variant="body1" color="primary">The BJJ 101 program now runs continuously through the year.  And at $69.00 per month it's really approachable.  Sign up, claim your spot, and we'll see you on the mats.</Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box sx={{
                      display: 'flex',
                      justifyContent: { xs: 'flex-start', md: 'center' },
                      alignItems: { xs: 'left', md: 'center' },
                      height: '100%'
                    }}>
                      <Box mr={2}>
                        <Button variant="contained" href={data.memberPortal.bjj101EnrollmentUrl} color="primary">Enroll Now</Button>
                      </Box>
                      {
                      /*
                      <Box>
                        <Button onClick={addToCalendarClickHandler} ref={addToCalendarButtonRef}>Add to Calendar</Button>
                      </Box>
                      */
                      }
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  )
}
