/* eslint-disable */
import data from '../../data'
const content = `
Young athletes excel at The Black Dog Jiu-Jitsu Company.

Early on, we made the decision to limit our youth program to ages ${data.ya.age.min} and older.  Our decision reflects the serious nature of our training pedagogy at the Black Dog Jiu-Jitsu Company;  We teach the same technique and curriculum to young athletes that is taught in our adult courses.  It’s a two way street though: we won’t water the material down but - in exchange - we require that young athletes show a requisite level of maturity on par with members in the adult courses.  

Athletes that enroll in the program benefit from the diverse group of individuals that constitute our Young Adult team as well as an extended community of youth grapplers;  Off-season wrestlers, cross-training Judo competitors and athletes at other local academies regularly drop in to sharpen their skills.

Young athletes are the future of our sport.  At The Black Dog we welcome your potential, your hard work, good humor and well earned accomplishments.  Come train.
`;

export default content
