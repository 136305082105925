import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'

// import ComingSoonOverlay from '../../utils/ComingSoonOverlay'
import locationImage from '../../../assets/gym.jpg'

import '../../../styles/Location.scss'
import data from '../../../data'
import openChat from '../../utils/Intercom'

function LocationLarge () {
  return (
    <Box id="location">
      <Grid container>
        <Grid item xs={12} md={6} >
            <Box
              id="googleMap"
              sx={{
                minHeight: {
                  xs: '100vw',
                  md: '50vw',
                  lg: '40vh'
                }
              }}
            >
              <iframe
                title="The Black Dog Jiu-Jitsu Company"
                src={data.map.url}
                frameBorder={0}
                allowFullScreen
              />
            </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{
            minHeight: '45vh'
          }}>
            <Box id="locationImage"
              sx={{
                backgroundImage: `url(${locationImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                width: '100%',
                height: '40vh'
              }}
            >
            </Box>
            <Box className="marginContainer">
              <List className="locationDetails">
                <ListItem><Typography variant="h6">{data.company.name}</Typography></ListItem>
                <ListItem><Typography variant="body1">{data.address.line1}</Typography></ListItem>
                <ListItem><Typography variant="body1">{data.address.line2}</Typography></ListItem>
                <ListItem><Typography variant="body1">{data.address.city}, {data.address.state}</Typography></ListItem>
                <ListItem><Typography variant="body1">{data.address.zip}</Typography></ListItem>
                <ListItem><Link href={`tel:${data.phone.areaCode}-${data.phone.number.replace(/\s/g, '')}`}>({data.phone.areaCode}) {data.phone.number}</Link></ListItem>
                <ListItem><Link onClick={openChat}>Send us a message</Link></ListItem>
              </List>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default LocationLarge
