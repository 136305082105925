import * as React from 'react'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
// import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
// import Divider from '@mui/material/Divider'

import SlideIn from '../../utils/SlideIn'
import {
  nonMembershipOptions
} from './data'

export default function NonMembershipOptionsPanel () {
  return (
    <React.Fragment>
      <Box id="nonmembershipoptions" className="marginContainer creme" mb={2}>
        <Container maxWidth="xl" disableGutters={true}>
          <SlideIn>
            <NonMembershipOptionSection />
          </SlideIn>
        </Container>
      </Box>
    </React.Fragment>
  )
}

function NonMembershipOptionSection () {
  const gridContainer = {
    display: 'grid',
    gridTemplateColumns: {
      xs: 'repeat(1, 1fr)',
      md: 'repeat(3, 1fr)'
    }
  }

  return (
    <Box>
      <Box sx={gridContainer} mb={2}>
        <Box mr={{ xs: 0, md: 2 }} mb={{ xs: 2, md: 0 }}>
          { nonFlexingMembershipOption(nonMembershipOptions[0], 0) }
        </Box>
        <Box mb={{ xs: 2, md: 0 }}>
          { nonFlexingMembershipOption(nonMembershipOptions[1], 1) }
        </Box>
        <Box ml={{ xs: 0, md: 2 }}>
          { nonFlexingMembershipOption(nonMembershipOptions[2], 2) }
        </Box>
      </Box>
      <Box sx={gridContainer}>
        <Box mr={{ xs: 0, md: 2 }} mb={{ xs: 2, md: 0 }}>
          { nonFlexingMembershipOption(nonMembershipOptions[3], 0) }
        </Box>
        <Box mr={{ xs: 0, md: 2 }} mb={{ xs: 2, md: 0 }}>
          { nonFlexingMembershipOption(nonMembershipOptions[4], 0) }
        </Box>
      </Box>
    </Box>
  )
}

function nonFlexingMembershipOption (option: any, i: number) {
  const ctaText = (option.buttonText) ? option.buttonText : 'Enroll'
  return (
    <Box sx={{ height: '100%' }}>
      <Paper sx={{ height: '100%' }}>
        <Box p={4} sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column'
        }}>
          <Box>
            <Box
              py={2}
              sx={{ height: '6rem' }}
            >
              <Typography
                variant="h5"
                color="primary"
                mb={2}
                sx={{
                  fontWeight: 'bold',
                  textTransform: 'uppercase'
                }}
              >{option.name}</Typography>
            </Box>
          </Box>
          <Box>
            <Box>
              <Typography variant="body1" color="primary">{option.description}</Typography>
            </Box>
          </Box>
          <Box sx={{
            marginTop: 'auto'
          }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
              p={2}
            >
              <Box
                sx={{
                  display: 'block',
                  textAlign: 'center',
                  alignSelf: 'flex-end'
                }}
              >
                <Typography variant="body1" color="primary" mb={2}>{option.price}</Typography>
                <Button href={option.link} variant="contained">{ctaText}</Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  )
}
