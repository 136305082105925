import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import { HashLink } from 'react-router-hash-link'

import Image2 from '../../../assets/crossashi.jpg'
import data from '../../../data'

export default function SecondaryCTA () {
  const backgroundImage = {
    backgroundImage: `url(${Image2})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  }

  const SecondaryCTABody = 'Nobody should join a Brazilian Jiu-Jitsu gym sight unseen.  We\'re committed to making sure that your first experience at The Black Dog Jiu-Jitsu Company is really inviting. Come train and see what you think - register now and join us on the mats.'

  return (
    <Box id="secondarycta" style={backgroundImage} className="marginContainerTall dimmedImageDark">
      <Container maxWidth="xl" disableGutters={true}>
        <Grid container>
          <Grid item md={8}>
            <Typography variant="h1" sx={{ fontSize: { xs: '48px', md: '90px' } }}>Register for a free trial class</Typography>
            <Typography mb={1} variant="subtitle1" sx={{ fontWeight: 'bold', textTransform: 'uppercase' }}>Come check out the gym, meet the team and train with us at The Black Dog.</Typography>
            <Typography mb={2} variant="subtitle1">{SecondaryCTABody}</Typography>
            <Box mb={3}>
              <Link component={HashLink} color="secondary.dark" to="/faq#trial-membership">Have Questions? Check out the FAQ.</Link>
            </Box>
          </Grid>
          <Grid item md={4}>
            <Box
              style={{ height: '100%' }}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Button href={data.calendar.bookingUrl} variant="contained" color="secondary">Register Now</Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}
