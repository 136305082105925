import * as React from 'react'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import { HashLink } from 'react-router-hash-link'

import Image2 from '../../../assets/ashigarami.jpg'
import openChat from '../../utils/Intercom'

export default function Questions () {
  const backgroundImage = {
    backgroundImage: `url(${Image2})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  }

  return (
    <Box id="check-the-faqs" sx={backgroundImage} className="marginContainerTall dimmedImageDark">
      <Container maxWidth="xl" disableGutters={true}>
        <Grid container>
          <Grid item md={12}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', textTransform: 'uppercase' }}>Still have questions? Check the FAQs</Typography>
            <Typography mb={2} variant="body1" color="secondary">There are a ton of questions (and answers) about membership on the Frequently Asked Questions page.  You'll probably find the answer you're looking for there.  Otherwise feel free to <Link onClick={openChat} style={{ cursor: 'pointer' }} color="secondary.dark">send us a message</Link> and we'll get back to you as soon as we can.</Typography>
            <Box>
              <Link component={HashLink} to="/faq#bjj101" color="secondary.dark">Check the FAQs</Link>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}
