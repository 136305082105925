import * as React from 'react'
import Box from '@mui/material/Box'
// import Divider from '@mui/material/Divider'
import Hero from './Hero'
import Gi from './Gi'
import NoGi from './NoGi'
import BJJ101 from './BJJ101'
import MailingList from '../../MailingList'
import StoreAd from '../../StoreAd'

import '../../../styles/Courses.scss'

function Courses () {
  return (
    <Box id="courses" className="black">
      <Hero />
      <Gi />
      <BJJ101 />
      <NoGi />
      <StoreAd />
      <MailingList />
    </Box>
  )
}

export default Courses
