import * as React from 'react'
import Box from '@mui/material/Box'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import useMediaQuery from '@mui/material/useMediaQuery'

export default function MasonryImageList (props: any) {
  const { images } = props
  const smallScreen = !useMediaQuery('(min-width:900px)')
  return (
    <Box sx={{
      width: '100%'
      // height: '100vh',
      // overflowY: 'scroll'
    }}>
      <ImageList
        variant="masonry"
        cols={(smallScreen) ? 1 : 3 }
        gap={8}
      >
        {images.map((item: any) => (
          <ImageListItem key={item.img}>
            <img
              src={`${item.img}?w=248&fit=crop&auto=format`}
              srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
              alt={item.title}
              loading="lazy"
            />
          </ImageListItem>
        ))}
      </ImageList>
    </Box>
  )
}
