import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'

// import SlideIn from '../../utils/SlideIn'
// import ComingSoonOverlay from '../../utils/ComingSoonOverlay'
import locationImage from '../../../assets/gym.jpg'
import '../../../styles/Location.scss'
import openChat from '../../utils/Intercom'
import data from '../../../data'

function LocationSmall () {
  return (
    <Box id="location" className="creme">
      <Box sx={{ height: '45vh' }}>
        <Box
          id="googleMap"
        >
          <iframe
            title="The Black Dog Jiu-Jitsu Company"
            src={data.map.url}
            frameBorder={0}
            allowFullScreen
          />
        </Box>
      </Box>
      <Grid container>
        <Grid item xs={12}>
          <Box
            id="locationImage"
            sx={{
              backgroundImage: `url(${locationImage})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              width: '100%',
              height: '45vh'
            }}
          ></Box>
        </Grid>
        <Grid item xs={12}>
          <Box className="marginContainer">
            <Grid item sm={12}>
              <List className="locationDetails">
                <ListItem><Typography variant="h6">{data.company.name}</Typography></ListItem>
                <ListItem><Typography variant="body1">{data.address.line1}</Typography></ListItem>
                <ListItem><Typography variant="body1">{data.address.city}, {data.address.state}</Typography></ListItem>
                <ListItem><Typography variant="body1">{data.address.zip}</Typography></ListItem>
                <ListItem><Link href={`tel:${data.phone.areaCode}-${data.phone.number.replace(/\s/g, '')}`}>({data.phone.areaCode}) {data.phone.number}</Link></ListItem>
                <ListItem><Link onClick={openChat}>Send us a message</Link></ListItem>
              </List>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default LocationSmall
