import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'

import SlideIn from '../../utils/SlideIn'

export default function RulesGeneral () {
  const banned = [
    'Striking (punching, kicking, elbow strike, headbutting, knee strikes, etc)',
    'Eye Gouging',
    'Fish Hooking',
    'Intentional scratching, hair pulling or biting',
    'Small joint manipulation (pulling on individual fingers or toes)',
    'Intentionally throwing your opponent onto their head',
    'Spitting and/or use of bodily excretion',
    'Inhibiting an opponent\'s ability to breathe with the hand',
    'Intentional contact with the groin, breasts or bodily orifices',
    'Overt intimidation',
    'Intentionally ignoring an opponent\'s tap'
  ]

  return (
    <Box id="bannedtechniques" className="marginContainerHorizontal creme">
      <SlideIn>
        <Typography variant="h5" mb={2}>Banned Techniques</Typography>
        <Typography variant="body1" mb={1}>The following is a list of banned techniques.  These techniques may not be employed under any circumstances at The Black Dog Jiu-Jitsu Company. Depending on the severity and circumstances, usage of a banned technique may result in immediate termination of membership at The Black Dog Jiu-Jitsu Company.</Typography>
        <Box pl={3} mb={2}>
          <List
            dense={true}
            sx={{
              listStyleType: 'disc'
            }}

          >
            {banned.map((rule: any, i: number) => (
              <ListItem key={i} sx={{ display: 'list-item' }}>
                <Typography variant="body1">{rule}</Typography>
              </ListItem>
            ))}
          </List>
        </Box>
      </SlideIn>
    </Box>
  )
}
