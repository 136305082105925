import * as React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
// import Logo from '../../svgs/logo'
import AltText from '../../svgs/AltText'
import AltTextSub from '../../svgs/AltTextSub'
import banner from '../../../assets/wolf-white.svg'

import '../../../styles/Hero.scss'

const backgroundImage = {
  backgroundImage: `url(${banner})`,
  backgroundSize: { xs: '130%', md: '100%' },
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat'
}

export default function Hero () {
  return (
    <Box id="mainCTA" py={4} sx={backgroundImage}>
      <Grid container>
        <Grid item xs={12}>
          <Box sx={{
            height: { xs: '65vh', md: '90vh' },
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
          }}>
            <Box sx={{
              width: '80%',
              paddingBottom: '20px'
            }}>
              <AltText
                width="100%"
                height="100%"
                viewBox="0 0 3622 1298"
                fill="white"
              />
            </Box>
            <Box sx={{ maxWidth: '40%' }}>
                <AltTextSub
                  width="100%"
                  height="auto"
                  viewBox="0 0 1289 262"
                  fill="white"
                />
              </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
