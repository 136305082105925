import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import noGiImage from '../../../assets/ashigarami.jpg'
import content from '../../content/courses-nogi'
import ContentBlock from './Content'
import SlideIn from '../../utils/SlideIn'

export default function NoGi () {
  return (
    <Box
      id="nogi"
      className="marginContainerTall creme"
    >
      <SlideIn>
        <Container maxWidth="xl" disableGutters={true}>
          <ContentBlock
            id="nogi"
            title="No-Gi Jiu-Jitsu"
            content={content}
            image={noGiImage}
            imageAlt="Training No-Gi Jiu-Jitsu"
            reverse={true}
            subtitle="Submission Grappling"
            comingSoon={true}
          />
        </Container>
      </SlideIn>
    </Box>
  )
}
