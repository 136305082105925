import React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import Link from '@mui/material/Link'
import TempYA1 from '../../../assets/teens1.jpg'
import TempYA2 from '../../../assets/teens2.jpg'
import TempYA3 from '../../../assets/teens3.jpg'
import data from '../../../data'
import SlideIn from '../../utils/SlideIn'

function CommitmentPrincipal (props: any) {
  const backgroundImage = {
    backgroundImage: `url(${props.image})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    filter: 'grayscale(100%)'
  }
  const reverse = (props.reverse) ? 'row-reverse' : 'row'
  return (
    <Box>
      <Grid container direction={reverse}>
        <Grid item xs={12} md={6}>
          <Box
            className="dimmedImage"
            sx={{
              width: { xs: '100vw', md: '50vw' },
              height: { xs: '40vh', md: '50vw' },
              ...backgroundImage
            }}
          >
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            className="marginContainer"
            sx={{
              height: '100%',
              width: '100%'
            }}
          >
            <Box
              sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Box
                sx={{ width: '100%' }}
              >
                <Typography
                  variant="h3"
                  color="primary"
                  mb={1}
                  sx={{
                    textTransform: 'uppercase',
                    fontSize: { xs: '1.5em', md: '2.5em' }
                  }}
                >
                  { props.title }
                </Typography>
                { props.children }
              </Box>
          </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default function Commitment () {
  const smallScreen = !useMediaQuery('(min-width:600px)')

  /*
  const styling = {
    content: {
      fontSize: '14px'
    },
    header: {
      fontSize: '24px'
    }
  }
  */

  return (
    <Box id="commitment" className="creme">
      <Grid container spacing={2} pb={{ xs: 0, md: 4 }}>
        <Grid item xs={12}>
          <Box className="marginContainer">
            <Typography variant="h5" mb={1} sx={{ textTransform: 'uppercase' }}>
              Commitment to Young Athletes
            </Typography>
            <Typography variant="body1" mb={1}>
              Jiu-Jitsu can be one of those things in people's lives that brings them enormous satisfaction. From experience, we know that many people spend the better part of their week day-dreaming about their next, incredible open mat or training session.
            </Typography>
            <Typography variant="body1" mb={1}>
              We also recognize that Young Athletes need to achieve minimum standards of academic excellence before spending time on the mats. We strongly, strongly support our Young Athletes exceptional performance at school just as much as we recognize exceptional performance on the mats.
            </Typography>
            <Typography variant="body1" mb={1}>
              But expectations must be reciprocated.  As such, our commitment to our Young Adult members is three-fold:
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <SlideIn>
        <Grid container>
          <Grid item xs={12}>
            <CommitmentPrincipal
              title="Authenticity"
              image={TempYA3}
            >
              <Typography variant="body1" mb={1}>
                We’re committed to teaching/training only what is demonstrably relevant and effective.  Our curriculum is based exclusively on the state of the art in Brazilian Jiu-Jitsu, Submission Grappling, Judo, and Wrestling.
              </Typography>
            </CommitmentPrincipal>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                textAlign: (smallScreen) ? 'left' : 'right'
              }}
            >
              <CommitmentPrincipal
                title="Accountability"
                image={TempYA1}
                reverse={!smallScreen}
              >
                <Typography
                  variant="body1"
                  mb={1}
                >
                  Accountability is making sure that when your victories are earned, someone celebrates that success with you.  And, conversely, when/if you fail to achieve standards, someone's there to call you out on it.
                </Typography>
              </CommitmentPrincipal>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <CommitmentPrincipal
              title="Growth"
              image={TempYA2}
            >
              <Typography variant="body1" mb={1}>
                If you're willing put in the work, we'll make sure you see results.
              </Typography>
            </CommitmentPrincipal>
          </Grid>
        </Grid>
      </SlideIn>
      <Divider />
      <Grid container>
        <Grid item xs={12}>
          <Box className="marginContainer">
            <Typography variant="body1" mb={1}>Jiu-Jitsu can be a medium for success beyond the mats. And teenage years can be some of the most exciting and challenging years of a person's life. Seeing a member's acceptance letter to college is as much a source of celebration at The Black Dog as a competition win, or a new promotion.</Typography>
            <Link href={data.memberPortal.yaEnrollmentUrl} color="secondary.dark"><Typography variant="body1" mb={1}>Join the Young Adults team at The Black Dog Jiu-Jitsu Company.</Typography></Link>
            <Typography variant="body1" mb={1}>We're serious about your success. You should be too.</Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
