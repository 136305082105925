import * as React from 'react'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import data from '../../../data'
import Image2 from '../../../assets/passing.jpg'

export default function BJJ101 () {
  const backgroundImage = {
    backgroundImage: `url(${Image2})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  }

  const BJJ101Description = `
    Meet some new people, get in shape and learn the sport of Brazilian Jiu-Jitsu in a structured class environment geared towards beginners. Courses follow our proven syllabus to get you from day-one to ready-to-roll over a period of 8-10 weeks.`

  return (
    <Box id="bjj101" sx={backgroundImage} className="marginContainerTall dimmedImageDark">
      <Container maxWidth="xl" disableGutters={true}>
        <Grid container>
          <Grid item md={8}>
            <Typography variant="h1" sx={{ fontSize: { xs: '5rem', sm: '6rem' } }}>BJJ 101</Typography>
            <Typography mb={1} variant="subtitle1" sx={{ fontWeight: 'bold', textTransform: 'uppercase' }}>Learn the fundamentals of Brazilian Jiu-Jitsu.</Typography>
            <Typography mb={2} variant="body1" color="secondary">{BJJ101Description}</Typography>
            <Box mb={3}>
              <Link href="/bjj101" color="secondary.dark">Checkout the BJJ 101 page for more details.</Link>
            </Box>
          </Grid>
          <Grid item md={4}>
            <Box
              style={{ height: '100%' }}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Button variant="contained" href={data.memberPortal.enrollmentUrl} color="secondary">Enroll Now</Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}
