import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import EmbedVideo from '../../utils/EmbedVideo'
import videoBg from '../../../assets/hype2.mp4'

import '../../../styles/Hype.scss'
import data from '../../../data'

function HypeText () {
  return (
    <Box id="textContainer">
      <Typography
        variant="h2"
        color="secondary"
        mb={1}
        sx={{
          display: 'none',
          lineHeight: 1,
          fontSize: {
            xs: '20px',
            md: '35px'
          }
        }}>
        Bias to Action:
      </Typography>
      {
        data.hypeText.map((ngram: any, index: any) => (
          <Typography
            key={index}
            color="secondary.dark"
            sx={{
              textTransform: 'uppercase',
              fontWeight: '700',
              lineHeight: {
                xs: 0.9,
                md: 0.85
              },
              fontSize: {
                xs: '30px',
                sm: '90px',
                md: '150px'
              },
              letterSpacing: {
                xs: '-5px',
                md: '-10px'
              }
            }}>{ngram}</Typography>
        ))
      }
    </Box>
  )
}

export default function Hype () {
  return (
    <Box id="hype">
      <Box id="superContainer">
        <Box id="videoContainer">
          <EmbedVideo src={videoBg} />
        </Box>
        <HypeText />
      </Box>
    </Box>
  )
}
