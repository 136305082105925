import * as React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'

import images from './GalleryImages'
import '../../../styles/Gallery.scss'

function GalleryImages () {
  return (
    <ImageList id="imageSlider" gap={1} sx={{
      gridAutoFlow: 'column',
      gridTemplateColumns: 'repeat(auto-fit, minmax(360px,1fr)) !important',
      gridAutoColumns: 'minmax(360px, 1fr)'
    }}>
      { images.map((item, i) => (
        <ImageListItem key={i}>
            <img
              width="100%"
              height="auto"
              src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
              srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
              alt={item.title}
              loading="lazy"
            />
        </ImageListItem>
      ))}
    </ImageList>
  )
}

export default function Gallery () {
  return (
    <Box id="gallery" className="creme">
      <Grid container>
        <Grid item xs={12}>
          <GalleryImages />
        </Grid>
      </Grid>
    </Box>
  )
}
