import * as React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import Hero from './Hero'
import Hype from './Hype'
import SmallHype from './SmallHypeV2'
import AboutSummary from './AboutSummary'
import Location from './Location'
import Schedule from './Schedule'
import Courses from './Courses'
import BJJ101 from './BJJ101'
import SecondaryCTA from './SecondaryCTA'
import MailingList from '../../MailingList'
import SocialMedia from '../../SocialMedia'
import Archive from './ShortArchive'
import OtherResources from './OtherResources'

function Home () {
  const smallScreen = !useMediaQuery('(min-width:600px)')

  return (
    <div>
      <Hero />
      {smallScreen ? <SmallHype /> : <Hype /> }
      <AboutSummary />
      <BJJ101 />
      <Location />
      <SocialMedia />
      <Courses />
      <Schedule />
      <Archive />
      <OtherResources />
      <MailingList />
      <SecondaryCTA />
    </div>
  )
}

export default Home
