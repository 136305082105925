import * as React from 'react'
import Box from '@mui/material/Box'
import ReactMarkdown from 'react-markdown'

import TwoColumnHero from '../../TwoColumnHero'
import Image2 from '../../../assets/kneeonbelly2.jpg'
import bjj101 from '../../content/bjj101'

export default function Hero () {
  return (
    <TwoColumnHero
      title="BJJ 101"
      subtitle="Introduction to Brazilian Jiu-Jitsu"
      image={Image2}
    >
      <Box sx={{
        color: '#ffffff',
        marginTop: '-1em'
      }}
      >
        <ReactMarkdown>{bjj101}</ReactMarkdown>
      </Box>
    </TwoColumnHero>
  )
}
