const data = {
  mailingList: {
    url: 'https://gmail.us21.list-manage.com/subscribe/post',
    u: '32a645fab0fda94c3479e0b99',
    id: '384c3c453e',
    fields: {
      email: 'MERGE0',
      firstname: 'MERGE1',
      lastname: 'MERGE2'
    }
  },
  ya: {
    age: {
      min: '10',
      max: '17'
    }
  },
  hypeText: ['Get to', 'the gym.', 'Be competitive.', 'Be consistent.', 'Innovate.', 'Stay hungry', 'and', 'win together.'],
  map: {
    url: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2800.70786917161!2d-122.66439672365453!3d45.415230171073276!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x549574851a01c55b%3A0x70644cbb67a0ab1d!2s25%20S%20State%20St%20STE%202115%2C%20Lake%20Oswego%2C%20OR%2097034!5e0!3m2!1sen!2sus!4v1682638995131!5m2!1sen!2sus'
  },
  calendar: {
    id: '303cb12e1e66dc1768b81444ea0284a196920e85037a2bfa33374393ce248a8f@group.calendar.google.com',
    apiKey: 'AIzaSyC-MiFZJmdsEFVU4dfgOgrnzZ9acS2aMQw',
    publicUrl: 'https://calendar.google.com/calendar/embed?src=303cb12e1e66dc1768b81444ea0284a196920e85037a2bfa33374393ce248a8f%40group.calendar.google.com&ctz=America%2FLos_Angeles',
    bookingUrl: 'https://calendar.app.google/hbwyjUsBC3pRhoxd8'
  },
  store: {
    url: 'https://store.theblackdogjiujitsu.com/'
  },
  memberPortal: {
    leadForm: 'https://docs.google.com/forms/d/e/1FAIpQLSfO7aIOLVtGMsoU0fVoBBHgen4y6-WPlly6Fgk7lmYiW_-nyQ/viewform?usp=sf_link',
    freezeForm: '/membership-freeze',
    membershipAgreement: '/membership-agreement',
    options: '/enroll/options',
    managementUrl: '/enroll/management',
    enrollmentUrl: '/enroll/membership',
    oneYearEnrollmentUrl: '/enroll/one-year-membership',
    monthToMonth: '/enroll/month-to-month-membership',
    allEnrollmentUrl: '/enroll/all',
    yaEnrollmentUrl: '/enroll/ya',
    bjj101EnrollmentUrl: '/enroll/bjj101',
    dayPassUrl: '/enroll/daypass',
    oneWeekUrl: '/enroll/oneweek',
    openMat: '/enroll/openmat',
    tenSessionPunchcard: '/enroll/ten-session-punchcard',
    fiveSessionPunchcard: '/enroll/five-session-punchcard'
  },
  social: {
    facebookUrl: 'https://www.facebook.com/people/The-Black-Dog-Jiu-Jitsu-Company/100088914739381/',
    instagramUrl: 'https://www.instagram.com/theblackdogjiujitsu/',
    twitterUrl: 'https://twitter.com/theblackdogjjco',
    dojoOutfitters: 'https://www.dojooutfitters.com/'
  },
  company: {
    name: 'The Black Dog Jiu-Jitsu Company',
    shortname: 'The Black Dog Jiu-Jitsu Co.',
    email: {
      info: 'info@theblackdogjiujitsu.com',
      bjj101: 'bjj101@theblackdogjiujitsu.com',
      billing: 'billing@theblackdogjiujitsu.com'
    },
    parentCorp: {
      name: 'Big Idea LLC',
      url: 'https://bigideacorporation.com'
    }
  },
  address: {
    line1: '25 S. State St.',
    line2: 'Suite 2115',
    city: 'Lake Oswego',
    state: 'Oregon',
    zip: '97034'
  },
  phone: {
    areaCode: '503',
    number: '512 - 9464'
  }
}

export default data
