import * as React from 'react'
import Box from '@mui/material/Box'
import MailingList from '../../MailingList'
import Hero from './Hero'
import Enrollment from './Enrollment'
import Commitment from './Commitment'
import NoteworthyAthletes from './NoteworthyAthletes'
import Gallery from './Gallery'
import CheckTheFAQs from './CheckTheFAQs'
import '../../../styles/YA.scss'

function YoungAdults () {
  return (
    <Box>
      <Hero />
      <Enrollment />
      <Gallery />
      <Commitment />
      <NoteworthyAthletes />
      <CheckTheFAQs />
      <MailingList />
    </Box>
  )
}

export default YoungAdults
