import * as React from 'react'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'

import Hero from './Hero'
import GeneralDecorum from './RulesDecorum'
import BannedTechniques from './BannedTechniques'
import RulesGeneral from './RulesGeneral'
import RulesGi from './RulesGi'
import RulesNoGi from './RulesNoGi'
import RuleRespect from './RulesRespect'

function Rules () {
  return (
    <Box id="rules" className="creme">
      <Hero />
      <RulesGeneral />
      <GeneralDecorum />
      <BannedTechniques />
      <RulesGi />
      <RulesNoGi />
      <Box mb={6}>
        <Divider />
      </Box>
      <RuleRespect />
    </Box>
  )
}

export default Rules
