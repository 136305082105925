import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'

import SlideIn from '../../utils/SlideIn'

export default function RulesGeneral () {
  const decorum = [
    'No shoes on the mat',
    'Wear sandals in the bathroom and wash your hands when you\'re done',
    'Keep your clothes on in the training area',
    'Clip your fingernails and your toenails',
    'Maintain good body hygeine',
    'Make sure your training clothes are clean',
    'Don\'t eat on the mats',
    'Please silence your electronic devices',
    'No video without explicit consent',
    'Limit photography to the beginning or end of the training session',
    'Obtain consent to post photographs of people on social media',
    'Limit the trash talk - defer to speaking to one another with respect',
    'If you\'re sick, stay home',
    'Existing cuts/wounds must be bandaged to prevent any exposure of the facility or training partners to blood or bodily fluids. Any open wounds occurring during training must be treated prior to rejoining training. '
  ]

  return (
    <Box id="decorum" className="marginContainerHorizontal creme">
      <SlideIn>
        <Typography variant="h5" mb={2}>Decorum</Typography>
        <Typography variant="body1" mb={1}>Let's behave like civilized human beings, shall we?  The following is a list of easy / obvious guidelines for us to abide by in order to preserve the overall approachability our gym.  Honestly, if you have a problem with any of this you probably need to go talk to somebody.</Typography>
        <Box pl={3} mb={2}>
          <List
            dense={true}
            sx={{
              listStyleType: 'disc'
            }}

          >
            {decorum.map((rule: any, i: number) => (
              <ListItem key={i} sx={{ display: 'list-item' }}>
                <Typography variant="body1">{rule}</Typography>
              </ListItem>
            ))}
          </List>
        </Box>
      </SlideIn>
    </Box>
  )
}
