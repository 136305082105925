import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Link from '@mui/material/Link'

import RuleIcon from '@mui/icons-material/Rule'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'

import SlideIn from '../../utils/SlideIn'

function Item (props: any) {
  return (
    <Paper>
      <Link href={props.link} underline="none">
        <Box sx={{ textAlign: 'center' }} p={3}>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '250px'
          }}>
            {props.children}
          </Box>
          <Typography variant="body1" color="primary">{props.body}</Typography>
        </Box>
      </Link>
    </Paper>
  )
}

export default function NextSteps () {
  return (
    <Box className="marginContainer">
      <Grid container>
        <Grid item xs={12}>
          <SlideIn>
            <Typography variant="h4" mb={2}>What's Next</Typography>
            <Typography variant="body1" mb={2}>We're here to make sure your training exprience is excellent.  In advance of your session take a few minutes to review our gym policies (spoiler: they're mostly just common sense) and read any of the relevant FAQs.  And if you do have additioanl questions, don't hesitate to <Link href="mailto:info@theblackdogjiujitsu.com" color="secondary.dark">reach out</Link>.</Typography>
            <Grid
              container
              spacing={2}
              py={2}
            >
              <Grid item xs={12} md={4}>
                <Item
                  body="Have a look at the FAQs."
                  image={QuestionAnswerIcon}
                  imageAltText="FAQs"
                  link="/faq"
                >
                  <QuestionAnswerIcon sx={{ fontSize: '100px' }} />
                </Item>
              </Grid>
              <Grid item xs={12} md={4}>
                <Item
                  body="Read the rules to familiarize yourself with how we train."
                  imageAltText="Rules"
                  link="/rules"
                >
                  <RuleIcon sx={{ fontSize: '100px' }} />
                </Item>
              </Grid>
              <Grid item xs={12} md={4}>
                <Item
                  body="Take a look at the schedule."
                  link="/#schedule"
                >
                  <CalendarMonthIcon sx={{ fontSize: '100px' }} />
                </Item>
              </Grid>
            </Grid>
          </SlideIn>
        </Grid>
      </Grid>
    </Box>
  )
}
