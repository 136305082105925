import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'

import SlideIn from '../../utils/SlideIn'

function createData (
  name: string,
  beginner: boolean,
  intermediate: boolean,
  advanced: boolean,
  elite: boolean
) {
  return { name, beginner, intermediate, advanced, elite }
}

const rows = [
  createData('Armbar', true, true, true, true),
  createData('Triangle', true, true, true, true),
  createData('Shoulder Lock', true, true, true, true),
  createData('Omaplata', true, true, true, true),
  createData('Guillotine', true, true, true, true),
  createData('Rear Naked Choke', true, true, true, true),
  createData('Ezekiel Choke', true, true, true, true),
  createData('Inverted Shoulder Strangle (D\'Arce/Anaconda)', true, true, true, true),
  createData('Bicep Crush', true, true, true, true),
  createData('Straight Ankle Lock', true, true, true, true),
  createData('Calf Crush', true, true, true, true),
  createData('Reaping the Leg', false, true, true, true),
  createData('Wrist Lock', false, true, true, true),
  createData('Toe Hold', false, true, true, true),
  createData('Stretching opponents legs apart', false, true, true, true),
  createData('Cervical Locks (Twister)', false, true, true, true),
  createData('Heel Hook', false, false, true, true),
  createData('Jumping Guard', false, false, true, true),
  createData('Slam from Guard', false, false, false, false),
  createData('Scissors Takedown', false, false, false, false),
  createData('Suplex', false, false, false, false),
  createData('Spiking Techniques', false, false, false, false),
  createData('Can Opener', false, false, false, false)
]

function renderCell (value: boolean) {
  return (
    <TableCell
      align="center"
      sx={{
        backgroundColor: setColor(value)
      }}
    >
      {checkOrX(value)}
    </TableCell>
  )
}

function checkOrX (value: boolean) {
  if (value) {
    return (<CheckIcon />)
  }
  return (<CloseIcon />)
}

function setColor (value: boolean) {
  return (value) ? '#4caf50' : '#ef5350'
}

export default function BasicTable () {
  return (
    <Box id="nogi" className="marginContainerHorizontal creme">
      <SlideIn>
        <Typography variant="h5" mb={2}>Training Without the Gi (No-Gi)</Typography>
        <Typography variant="body1" mb={1}>
          When rolling with your partner in a No-Gi setting, please observe the following rules.  Again, the rule set that you and your partner abide by should be respective to the individual with the lowest rank.  If you have questions about this guidance, please see the example above.
        </Typography>
        <Typography variant="body1" mb={1}>
          We are also aware that the rank categorization in a No-Gi context can be considerably more ambiguous than rankings in the Gi.  In particular, athletes frequently choose to train and compete in upper brackets when they may not currently hold a rank that qualifies them for that bracket.  If you feel like this situation applies to you, please reach out to management before training and, as always, communicate clearly with your partner.
        </Typography>
        <Box py={2}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Technique</TableCell>
                  <TableCell align="right">Beginner (White)</TableCell>
                  <TableCell align="right">Intermediate (Blue)</TableCell>
                  <TableCell align="right">Advanced (Purple, Brown)</TableCell>
                  <TableCell align="right">Elite (Black)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    {renderCell(row.beginner)}
                    {renderCell(row.intermediate)}
                    {renderCell(row.advanced)}
                    {renderCell(row.elite)}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </SlideIn>
    </Box>
  )
}
