import * as React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Divider from '@mui/material/Divider'

import venn from '../../../assets/martialartscombatsport.png'
import canelo from '../../../assets/canelo.jpg'
import karate from '../../../assets/karate.webp'

function DryCard (props: any) {
  return (
        <Card>
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">{props.title}</Typography>
                {props.children}
            </CardContent>
        </Card>
  )
}

function ComparisonCard (props: any) {
  return (
          <Card>
              <CardMedia
                  sx={{ height: 240 }}
                  image={props.image}
                  title={props.imageTitle}
              />
              <CardContent>
                  <Typography gutterBottom variant="h5" component="div">{props.title}</Typography>
                  {props.children}
              </CardContent>
          </Card>
  )
}

export default function Discussion () {
  return (
    <Box className="marginContainer creme">
      <Container maxWidth="xl" disableGutters={true}>
        <Grid container>
          <Grid item md>
            <Typography variant="h3" mb={1}>The Catch</Typography>
            <Typography variant="body1" mb={1}>The thing is, Brazilian Jiu-Jitsu is also commonly, and dare I say appropriately, described as a martial art.  And that makes us a little uncomfortable.</Typography>
            <Typography variant="body1" mb={3}>On some level, it begs us to have a better way of describing what we do.  Because it's not that.  And in full recognition of the fact that we are unlikely to change popular opinion on the phrase, we need some manner of providing distinction for ourselves if nothing else.</Typography>
            <Divider />
            <Grid container spacing={2} my={2}>
                <Grid item xs={12} md={6}>
                    <ComparisonCard title="Martial Art" image={karate} imageTitle="Martial Arts">
                        <Box px={2}>
                            <List sx={{ listStyleType: 'disc' }}>
                                <ListItem sx={{ display: 'list-item' }}>Non-western training clothing</ListItem>
                                <ListItem sx={{ display: 'list-item' }}>Emphasis on non-athletic mental attributes (discipline, respect, confidence)</ListItem>
                                <ListItem sx={{ display: 'list-item' }}>Unathletic modes of training</ListItem>
                                <ListItem sx={{ display: 'list-item' }}>Titles and ranks</ListItem>
                                <ListItem sx={{ display: 'list-item' }}>Vague, poorly defined or non-verifiable combat efficacy</ListItem>
                            </List>
                        </Box>
                    </ComparisonCard>
                </Grid>
                <Grid item xs={12} md={6}>
                    <ComparisonCard title="Combat Sport" image={canelo} imageTitle="Combat Sports">
                        <Box px={2}>
                            <List sx={{ listStyleType: 'disc' }}>
                                <ListItem sx={{ display: 'list-item' }}>Sporting competition with clearly defined scoring</ListItem>
                                <ListItem sx={{ display: 'list-item' }}>Western attire</ListItem>
                                <ListItem sx={{ display: 'list-item' }}>Non-hierarchical</ListItem>
                                <ListItem sx={{ display: 'list-item' }}>Demonstrable combat efficacy</ListItem>
                                <ListItem sx={{ display: 'list-item' }}>An emphasis on athletic, physical attributes (speed, strength, agility)</ListItem>
                            </List>
                        </Box>
                    </ComparisonCard>
                </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Typography variant="body1" mb={1}>Recognizing (assuming) that the above delineation more-or-less reflects how people think about the categories, it becomes fairly easy to start to  sort disciplines relative to these clearer terms.  Trivial examples are disciplines like Tai Chi and Aikido:  Obviously "Martial Arts".  Similarly, Boxing and Wrestling are clearly "Combat Sports".</Typography>
        <Typography variant="body1" mb={1}>In general, we can create a kind of continuum between the two categories as in some ways they represent polar opposites.  Below is a rudimentary Venn diagram representation:</Typography>
        <Grid item>
            <Box>
                <img src={venn} width="100%" height="auto" alt={'Image1'}/>
            </Box>
        </Grid>
        <Grid item>
            <Typography variant="body1" mb={1}>Easy work, right?  For the disciplines on either end of the spectrum, it's pretty clear why they sit where they sit, (although I'm starting to second guess fencing if I'm being honest.)</Typography>
            <Typography variant="body1" mb={1}>A little more detail:</Typography>
            <Box my={4}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <DryCard title="Karate">
                            <Box px={2}>
                                <List sx={{ listStyleType: 'disc' }}>
                                    <ListItem sx={{ display: 'list-item' }}>Lots of bowing and kata</ListItem>
                                    <ListItem sx={{ display: 'list-item' }}>So many ranks</ListItem>
                                    <Divider />
                                    <ListItem sx={{ display: 'list-item' }}>Some forms have been demonstrated to be effective, especially when paired with other styles</ListItem>
                                    <ListItem sx={{ display: 'list-item' }}>There are some competitive sporting events</ListItem>
                                </List>
                            </Box>
                        </DryCard>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <DryCard title="Muay Thai">
                            <Box px={2}>
                                <List sx={{ listStyleType: 'disc' }}>
                                    <ListItem sx={{ display: 'list-item' }}>Brutally, demonstrably effective</ListItem>
                                    <ListItem sx={{ display: 'list-item' }}>Highly competitive sporting events</ListItem>
                                    <Divider />
                                    <ListItem sx={{ display: 'list-item' }}>Some bowing and idiosyncratic dancing</ListItem>
                                    <ListItem sx={{ display: 'list-item' }}>Some non-western accessories</ListItem>
                                </List>
                            </Box>
                        </DryCard>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <DryCard title="Jiu-Jitsu">
                            <Box px={2}>
                                <List sx={{ listStyleType: 'disc' }}>
                                    <ListItem sx={{ display: 'list-item' }}>Demonstrably effective, especially when paired with striking disciplines</ListItem>
                                    <ListItem sx={{ display: 'list-item' }}>Lots of sporting events</ListItem>
                                    <Divider />
                                    <ListItem sx={{ display: 'list-item' }}>Gi's and ranks</ListItem>
                                    <ListItem sx={{ display: 'list-item' }}>Some bowing, esoteric nomenclature and "oss"</ListItem>
                                </List>
                            </Box>
                        </DryCard>
                    </Grid>
                </Grid>
            </Box>
            <Typography variant="h3" my={3}>Long story short: The Black Dog practices Jiu-Jitsu as both a martial art and a combat sport.</Typography>
            <Typography variant="body1" mb={1}>Combat sport is the easy part:  We understand the sporting rules of Jiu-Jitsu and regularly compete in local and national tournaments.  And when we train No-Gi, it’s really remarkably similar to Wrestling - albeit with key differences in scoring systems. In that sense, we find ourselves solidly in the “combat sport” cohort, a place where our gym likes to find itself.  When we train in this style athleticism, strength and conditioning really surface as key attributes, technique and strategy notwithstanding.</Typography>
            <Typography variant="body1" mb={1} sx={{ 'font-style': 'italic' }}>But now the hard part.  How does training at the Black Dog reflect "martial arts" given the somewhat dubious discussion insofar?</Typography>
            <Typography variant="body1" mb={1}>In particular, we wear a Gi regularly during training;  And that certainly meets the criteria concerning non-western training clothing.  Another clear hallmark are the ranks that we keep:  White belts graduate to become black belts with time.   And that’s pretty much where the overlap ends, ideally.</Typography>
        </Grid>
      </Container>
    </Box>
  )
}
