import * as React from 'react'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'

export default function BJJ101 () {
  return (
    <Container maxWidth="xl" disableGutters={true}>
      <Box
        id="gi"
        className="marginContainer black"
      >
        <Typography variant="h3" color="secondary">BJJ 101</Typography>
        <Typography variant="body1" color="secondary">For more information about the BJJ 101 program, have a look at the <Link href="/bjj101" color="secondary">BJJ 101</Link> page.</Typography>
      </Box>
    </Container>
  )
}
