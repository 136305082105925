import * as React from 'react'
import { Outlet } from 'react-router-dom'

import Nav from './Nav'
import Footer from './Footer'
import NotificationBanner from './NotificationBanner'

function Layout () {
  return (
    <div className="App">
      <h1 className="silent">Brazilian Jiu-Jitsu Classes in Lake Oswego, Oregon.  BJJ near me in Lake Oswego , Oregon.</h1>
      <Nav />
      <NotificationBanner />
      <Outlet />
      <Footer />
    </div>
  )
}

export default Layout
