import React, { useEffect } from 'react'
import type { RouteObject } from 'react-router-dom'
import { useRoutes } from 'react-router-dom'

import { ThemeProvider } from '@mui/material/styles'
// import { Navigate } from 'react-router-dom'
import Layout from './components/layout'
import Home from './components/pages/Home'
import Privacy from './components/pages/Privacy'
import TermsOfUse from './components/pages/TermsOfUse'
import FAQ from './components/pages/FAQ'
import NoMatch from './components/pages/NoMatch'
// import Enroll from './components/pages/Enroll'
import BJJ101 from './components/pages/BJJ101'
import YoungAdults from './components/pages/YoungAdults'
import Rules from './components/pages/Rules'
import About from './components/pages/About'
import Courses from './components/pages/Courses'
import Archive from './components/pages/Archive'
import Welcome from './components/pages/Welcome'
import Registered from './components/pages/Registered'
// import MembershipFreeze from './components/pages/MembershipFreeze'
import MembershipOptions from './components/pages/MembershipOptions'
import MembershipAgreement from './components/pages/MembershipAgreement'
import MartialArts from './components/pages/MartialArts'

import theme from './themes/theme'
import './styles/App.scss'

function External (props: any) {
  window.location.href = props.to
  return null
}

function App () {
  const routes: RouteObject[] = [
    {
      path: '/',
      element: <Layout />,
      children: [
        { index: true, element: <Home /> },
        { path: 'policies/privacy', element: <Privacy /> },
        { path: 'policies/terms-of-use', element: <TermsOfUse /> },
        { path: 'faq', element: <FAQ /> },
        { path: 'enroll', element: <MembershipOptions /> },
        { path: 'enroll/*', element: <MembershipOptions /> },
        { path: 'bjj101', element: <BJJ101 /> },
        { path: 'ya', element: <YoungAdults /> },
        { path: 'rules', element: <Rules /> },
        { path: 'about', element: <About /> },
        { path: 'courses', element: <Courses /> },
        { path: 'archive', element: <Archive /> },
        { path: 'welcome', element: <Welcome /> },
        { path: 'registered', element: <Registered /> },
        { path: 'martial-arts', element: <MartialArts /> },
        // { path: 'membership-freeze', element: <MembershipFreeze /> },
        { path: 'membership-agreement', element: <MembershipAgreement /> },
        { path: 'enroll/options', element: <MembershipOptions /> },
        { path: 'enroll/management', element: <External to="https://the-black-dog-jiu-jitsu.gymdesk.com/login" /> },
        { path: 'enroll/management', element: <External to="https://the-black-dog-jiu-jitsu.gymdesk.com/login" /> },
        { path: 'enroll/membership', element: <External to="https://the-black-dog-jiu-jitsu.gymdesk.com/signup/v/6kzgr" /> },
        { path: 'enroll/bjj101', element: <External to="https://the-black-dog-jiu-jitsu.gymdesk.com/signup/v/lWPne" /> },
        { path: 'enroll/daypass', element: <External to="https://the-black-dog-jiu-jitsu.gymdesk.com/signup/v/AvRPP" /> },
        { path: 'enroll/ya', element: <External to="https://the-black-dog-jiu-jitsu.gymdesk.com/signup/v/6POWj" /> },
        { path: 'enroll/oneweek', element: <External to="https://the-black-dog-jiu-jitsu.gymdesk.com/signup/v/Aqzxe" /> },
        { path: 'enroll/openmat', element: <External to="https://the-black-dog-jiu-jitsu.gymdesk.com/signup/v/lNOWL" /> },
        { path: 'enroll/all', element: <External to="https://the-black-dog-jiu-jitsu.gymdesk.com/signup/v/6a1ZN" /> },
        { path: 'enroll/month-to-month-membership', element: <External to="https://the-black-dog-jiu-jitsu.gymdesk.com/signup/v/lKdW0" /> },
        { path: 'enroll/one-year-membership', element: <External to="https://the-black-dog-jiu-jitsu.gymdesk.com/signup/v/65kLg" /> },
        { path: 'enroll/ten-session-punchcard', element: <External to="https://the-black-dog-jiu-jitsu.gymdesk.com/signup/v/A13da" /> },
        { path: 'enroll/five-session-punchcard', element: <External to="https://the-black-dog-jiu-jitsu.gymdesk.com/signup/v/65WEN" /> },
        { path: 'google-reviews', element: <External to="https://g.page/r/CZAGtbBXV1qOEAI/review" /> },
        { path: 'main-group-chat', element: <External to="https://ig.me/j/AbZHoZEUFaqGmOpT/" /> },
        { path: '*', element: <NoMatch /> }
      ]
    }
  ]

  const routesCompiled = useRoutes(routes)

  useEffect(() => {
    (window as any).Intercom('boot', {
      api_base: 'https://api-iam.intercom.io',
      app_id: 'r6z3rvxi'
    })
  })

  return (
    <ThemeProvider theme={theme}>
      {routesCompiled}
    </ThemeProvider>
  )
}

export default App
