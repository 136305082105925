import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import ReactMarkdown from 'react-markdown'
// import Image1 from '../../../assets/armbar2.jpg'
import Image2 from '../../../assets/Tim2.jpg'
import aboutSummary from '../../content/aboutSummary'
import FullLeftHero from '../../FullLeftHero'

export default function AboutSummary () {
  return (
    <FullLeftHero
      id="about"
      image={Image2}
      imageAlt="Image2"
      minHeight={{ xs: '40vh', sm: '80vh', md: '60vh', lg: '70vh' }}
    >
      <Typography variant="h5" mb={2} sx={{ fontSize: '24px' }}>
        We created The Black Dog Jiu-Jitsu Company to train the way that we’ve always felt was most rewarding.
      </Typography>
      <ReactMarkdown>
        {aboutSummary}
      </ReactMarkdown>
      <Box sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'left'
      }}>
        <Link href="about" color="secondary.dark">Continue Reading...</Link>
      </Box>
    </FullLeftHero>
  )
}
