import * as React from 'react'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'

import SlideIn from '../../utils/SlideIn'

import {
  membershipOptions
} from './data'

function OptionHighlight (props: any) {
  return (
    <Box
      px={2}
      sx={{
        position: 'relative',
        width: { xs: '60%', sm: '30%', md: '25%' },
        left: '-15px',
        top: '20px',
        backgroundColor: '#111111',
        boxShadow: 3
      }}
    >
      <Typography
        variant="subtitle1"
        color="secondary"
        sx={{
          fontWeight: 'bold',
          textTransform: 'uppercase'
        }}
      >{props.text}</Typography>
    </Box>
  )
}

function membershipOption (option: any, i: number) {
  const ctaText = (option.buttonText) ? option.buttonText : 'Enroll'
  return (
    <Box mb={2}>
      <Paper>
        <OptionHighlight text={option.highlight}></OptionHighlight>
        <Box p={4}>
          <Grid container>
            <Grid item xs={12} sm={3}>
              <Box py={2}>
                <Typography
                  variant="h5"
                  color="primary"
                  mb={2}
                  sx={{
                    fontWeight: 'bold',
                    textTransform: 'uppercase'
                  }}
                >{option.name}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Box p={{ xs: 0, sm: 2 }}>
                <Typography variant="body1" color="primary">{option.description}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
                p={2}
              >
                <Box
                  sx={{
                    display: 'block',
                    textAlign: 'center'
                  }}
                >
                  <Typography variant="body1" color="primary" mb={2}>{option.price}</Typography>
                  <Button href={option.link} variant="contained">{ctaText}</Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  )
}

export default function MembershipOptionsPanel () {
  return (
    <Box id="membership-options" className="marginContainer creme">
      <Container maxWidth="xl" disableGutters={true}>
        <SlideIn>
          {membershipOptions.map((option, i) => membershipOption(option, i))}
        </SlideIn>
      </Container>
    </Box>
  )
}
