import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
// import Grid from '@mui/material/Grid'

import SlideIn from '../../utils/SlideIn'

export default function RulesGeneral () {
  return (
    <Box id="general" className="marginContainerHorizontal creme" mt={4}>
      <SlideIn>
        <Box>
          <Typography variant="body1" mb={1}>The most general rule at The Black Dog Jiu-Jitsu Company is "protect yourself at all times". It really supercedes almost everything. We strongly recommend that you give that some serious consideration.</Typography>
          <Typography variant="body1" mb={1}>A close second is something short and sweet about communicating with your training partners.  A lot of the friction that occurs betwen parties can be resolved by communicating clearly and setting expectations upfront.</Typography>
          <Typography variant="body1">The above notwithstanding, the following rules should be observed at all times for all parties training at The Black Dog Jiu-Jitsu Company.</Typography>
        </Box>
      </SlideIn>
    </Box>
  )
}
