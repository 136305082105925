import React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import ReactMarkdown from 'react-markdown'

import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import faq from './faq'
import '../../../styles/FAQ.scss'

function FAQSection (props: any) {
  return (
    <Box id={props.data.id}>
      <Box py={{ xs: 0, md: 2 }}>
        <Box
          mb={1}
        >
          <Typography
            variant="subtitle1"
            color="primary"
            sx={{ fontWeight: 'bold', textTransform: 'uppercase' }}
          >
            {props.data.title}
          </Typography>
        </Box>
        <Box>
          {
            props.data.questions.map((q: any, i: number) => (
              <Accordion key={i}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${i}a-content`}
                  id={`panel${i}a-header`}
                >
                  <Typography>{q.question}</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{
                  backgroundColor: '#fcfcfc',
                  borderTop: '1px solid rgba(0, 0, 0, .125)'
                }}>
                  <Box>
                    <ReactMarkdown>{q.answer}</ReactMarkdown>
                  </Box>
                </AccordionDetails>
              </Accordion>
            ))
          }
        </Box>
      </Box>
    </Box>
  )
}

function FAQ () {
  return (
    <Box id="faqs" className="marginContainer creme">
      <Typography variant="h4" color="primary" mb={2}>Frequently Asked Questions</Typography>
      <Typography variant="body1" color="primary">You have questions, we have answers.</Typography>
      <Box py={4}>
        <Grid container spacing={{ xs: 2, md: 4 }}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <FAQSection data={faq.membership} />
              </Grid>
              <Grid item xs={12} md={4}>
                <FAQSection data={faq.bjj101} />
              </Grid>
              <Grid item xs={12} md={4}>
                <FAQSection data={faq.youngAdultClasses} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <FAQSection data={faq.firstTime} />
              </Grid>
              <Grid item xs={12} md={4}>
                <FAQSection data={faq.gymRules} />
              </Grid>
              <Grid item xs={12} md={4}>
                <FAQSection data={faq.generalQuestions} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <FAQSection data={faq.dropIns} />
              </Grid>
              <Grid item xs={12} md={4}>
                <FAQSection data={faq.openMat} />
              </Grid>
              <Grid item xs={12} md={4}>
                <FAQSection data={faq.privateLessons} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <FAQSection data={faq.holidaysInclementWeather} />
              </Grid>
              <Grid item xs={12} md={4}>
                <FAQSection data={faq.oneWeekFree} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default FAQ
