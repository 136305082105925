import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'

import SlideIn from '../../utils/SlideIn'

export default function RulesRespect () {
  return (
    <Box id="general" className="marginContainerHorizontal creme" pb={6}>
      <SlideIn>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Box
            sx={{
              width: {
                xs: '100vw',
                md: '40vw'
              }
            }}
          >
            <Paper>
              <Box p={3}>
                <Typography variant="h5" mb={2}>A final note...</Typography>
                <Typography variant="body1" mb={1}>We encourage individuals at the gym to practice tolerance.  People are different; We have different backgrounds, experiences and ideas.</Typography>
                <Typography variant="body1" mb={1} sx={{ fontStyle: 'italic' }}>That said, at The Black Dog Jiu-Jitsu Company we don't tolerate nonsense like racism, sexism and other forms of bigotry. Individuals who abuse the space to espouse intolerance will be asked to leave.</Typography>
              </Box>
            </Paper>
          </Box>
        </Box>
      </SlideIn>
    </Box>
  )
}
