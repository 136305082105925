import * as React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
// import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'

import Image2 from '../../../assets/kneeonbelly2.jpg'
// import data from '../../../data'

const backgroundImage = {
  backgroundImage: `url(${Image2})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center'
}

export default function Hero () {
  return (
    <Box className="marginContainer dimmedImageDark" sx={backgroundImage}>
      <Box sx={{ minHeight: { xs: '30vh', sm: '30vh' } }}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Typography
              variant="h1"
              sx={{ fontSize: { xs: '3rem', sm: '4rem', md: '4rem', lg: '5rem' } }}
              mb={2}
            >Registration Complete</Typography>
          </Grid>
          <Grid item xs={12} md={6} sx={{ zIndex: 5 }}>
            <Box pl={{ md: 4 }}>
              <Typography
                variant="subtitle1"
                mb={1}
                color="secondary"
                sx={{
                  fontWeight: 'bold',
                  textTransform: 'uppercase'
                }}
              >Thanks for completing your registration!</Typography>
              <Typography variant="body1" mb={1} color="secondary">You've completed all the necessary steps in order to train -  but we'd also encourage you to touchbase with one of staff members at the gym to make sure everything is in order.</Typography>
              <Typography variant="body1" mb={1}color="secondary">You should see a couple of emails in your inbox very shortly; If you paid for a Day Pass or a One-Week Limited Membrship you'll receive one email with details about your credit card transaction and another with copies of the documents you signed. Otherwise, if you filled out a Open Mat registration, you'll just receive copies of the signed documents.</Typography>
              <Typography variant="body1" mb={1}color="secondary">Again &mdash; Thank You &mdash; and welcome to The Black Dog.</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
