import * as React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'

import TwoColumnHero from '../../TwoColumnHero'
import Image2 from '../../../assets/kneeonbelly2.jpg'
import openChat from '../../utils/Intercom'

export default function Hero () {
  return (
    <TwoColumnHero
      title="Membership Options"
      image={Image2}
    >
      <Box>
        <Typography variant="body1" color="secondary">
          There are a handful of ways that you can come train at the Black Dog Jiu-Jitsu Company.  If you have any questions about any of the options below, feel free to ask us in person or <Link onClick={openChat} style={{ cursor: 'pointer' }} color="secondary.dark">send us a message</Link>.
        </Typography>
      </Box>
    </TwoColumnHero>
  )
}
