import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'

import SlideIn from '../../utils/SlideIn'
import data from '../../../data'
import app from '../../../assets/app.png'

export default function MemberPortal () {
  return (
    <Box sx={{ minHeight: { xs: '200px', sm: '400px' } }}>
      <SlideIn>
        <Box py={8}>
          <Grid container>
            <Grid item xs={12} md={6} mb={{ xs: 6, md: 0 }}>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img src={app} alt="The Black Dog Jiu-Jitsu Company" className="Member Portal" width="40%"/>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box px={4}>
                <Typography variant="h4" mb={2}>Member Portal</Typography>
                <Typography variant="body1">During registration you created a new account in the <Link color="secondary.dark" href={data.memberPortal.managementUrl} rel="_blank">Member Portal</Link>. The Member Portal can be used to:</Typography>
                <List
                  sx={{
                    listStyleType: 'disc',
                    pl: 2,
                    '& .MuiListItem-root': {
                      display: 'list-item'
                    }
                  }}
                >
                  <ListItem>
                    <ListItemText>Change your billing method</ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>See past billings</ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>Add family members to your membership</ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>A bunch of other features</ListItemText>
                  </ListItem>
                </List>
                <Typography variant="body1">If you have any questions about the membership portal, feel free to reach out to us at <Link color="secondary.dark" href="mailto:info@theblackdogjiujitsu.com">info@theblackdogjiujitsu.com</Link>.</Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </SlideIn>
    </Box>
  )
}
