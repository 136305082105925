import image1 from '../../../assets/bjj101ArchiveImages/BlackDogJJ_01.jpg'
import image2 from '../../../assets/bjj101ArchiveImages/BlackDogJJ_04.jpg'
import image3 from '../../../assets/bjj101ArchiveImages/BlackDogJJ_22.jpg'
import image4 from '../../../assets/bjj101ArchiveImages/BlackDogJJ_45.jpg'

const images = [
  {
    img: image1,
    title: 'Training 1'
  },
  {
    img: image2,
    title: 'Training 2'
  },
  {
    img: image3,
    title: 'Training 3'
  },
  {
    img: image4,
    title: 'Training 4'
  }
]

export default images
