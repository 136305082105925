import React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import ReactMarkdown from 'react-markdown'

import SlideIn from '../../utils/SlideIn'
import Signature from '../../svgs/Signature'
import Tim from '../../../assets/Tim2.jpg'
import about from '../../content/about'

export default function AboutFullMobile () {
  return (
    <Box id="about" className="creme">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box sx={{
              overflow: 'auto'
            }}>
              <img src={Tim} width="100%" height="auto" alt={'Image1'}/>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <SlideIn>
              <Box className="marginContainerHorizontal">
                <Typography variant="h5" mb={2} sx={{ fontSize: '24px' }}>
                  We created The Black Dog Jiu-Jitsu Company to train the way that we’ve always felt was most rewarding.
                </Typography>
                <ReactMarkdown>
                  {about}
                </ReactMarkdown>
                <Box sx={{ textAlign: 'center', width: '100%' }} my={6}>
                  <Signature fill="#111" width="40%" />
                </Box>
              </Box>
            </SlideIn>
          </Grid>
        </Grid>
    </Box>
  )
}
