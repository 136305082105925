import * as React from 'react'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Table from '@mui/material/Table'
import Link from '@mui/material/Link'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'

// import data from '../../../data'
import SlideIn from '../../utils/SlideIn'

const rows = [
  {
    week: 'Week 1',
    positions: 'Standing, Turtle, Back Mount',
    submissions: 'Rear Naked Choke',
    notes: 'Introduction Week.  We\'ll get going right away with am Arm Drag from a standing position.  From the Arm Drag we progress to am easy trip to the ground and top control from the Turtle position.  Once we control the Turtle position we\'ll move to a Head-and-Arm Turnover in order to achieve the back position.  Once we have the back, we\'ll explore the Rear Naked Choke to finish.'
  },
  {
    week: 'Week 2',
    positions: 'Standing, Open-Guard, Side Control',
    submissions: 'Kimura, Americana, Straight Arm Lock',
    notes: 'This week starts standing, again with a Arm Drag.  From the Arm Drag we\'ll execute a Single-Leg takedown.  Once we\'ve taken our opponent down, we\'ll pass the guard using a Knee-Slice pass to Side-Control.  After we\'ve established Side-Control, we\'ll study the Kimura, Americana and Straight Arm Lock submissions.'
  },
  {
    week: 'skip',
    positions: '',
    submissions: '',
    notes: ''
  },
  {
    week: 'Capstone Week',
    positions: 'All',
    submissions: 'All',
    notes: 'We\'ll finish out our BJJ 101 course with a broad review of the positions, submissions and transitions that we\'ve covered over the last three months.  Students will get more time to practive their prefered techniques in positional rounds.  Finally, we\'ll introduce the students to the concept of "Flow Rolling" as a means to warm up for true randori.  Finally, we\'ll complete some randori sessions both as a class as well as with the broader Black Dog Team.  Celebration to follow.'
  }
]

export default function Curriculum () {
  return (
    <Box id="curriculum" className="creme">
      <SlideIn>
        <Box className="marginContainerTall">
          <Container maxWidth="xl" disableGutters={true}>
            <Grid container>
              <Box mb={2}>
                <Typography variant="h4">Syllabus</Typography>
              </Box>
              <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Week</TableCell>
                        <TableCell align="right">Positions Covered</TableCell>
                        <TableCell align="right">Submissions Covered</TableCell>
                        <TableCell align="center">Description</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row, i) => {
                        if (row.week === 'skip') {
                          return (
                            <TableRow
                              key={i}
                              sx={{
                                '&:last-child td, &:last-child th': { border: 0 },
                                backgroundColor: '#f5f5f5'
                              }}
                            >
                              <TableCell align="center" colSpan={4}>
                                <Box sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  minHeight: '50px'
                                }}><Typography variant="h5">&#8942;</Typography></Box>
                              </TableCell>
                            </TableRow>
                          )
                        }
                        return (
                          <TableRow
                            key={i}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 }
                            }}
                          >
                            <TableCell component="th" scope="row">
                              <Typography variant="body1" display="inline">{row.week}</Typography>
                            </TableCell>
                            <TableCell align="right">{row.positions}</TableCell>
                            <TableCell align="right">{row.submissions}</TableCell>
                            <TableCell align="left">{row.notes}</TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box sx={{ width: '100%', textAlign: 'right' }} py={2}>
                  <Typography variant="body1"><Link href="/syllabus.txt" color="primary" download>Download the complete syllabus</Link>.</Typography>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </SlideIn>
    </Box>
  )
}
