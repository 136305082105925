/* eslint-disable */
const about = `
The recipe for good training is self-evident:  When the room is right, training hard is effortless. It&apos;s something we look forward to every day.  And when we train every day, brilliant things happen;  We evolve and our teammates evolve with us.  We become better versions of ourselves, together.

The irony is that by focusing on ourselves everyone gets better.  And by trying to strangle and break each other, we learn to trust one another.  The converse is also true:  When we focus our attention on those around us - on the room - we as individuals grow in profound, unexpected ways.

At The Black Dog Jiu-Jitsu Company, we’re here to provide you with an ideal training environment and world-class instruction. It’s a simple mandate.

Jiu-Jitsu is not easy.  It's an endeavor that asks us for as much as we care to give, and more.  It's a journey with no certain destination.  But in those travels there are unexpected, existential rewards available to those brave enough to traverse the path before them.

Please, come train and see if The Black Dog makes sense for you.
`;

export default about;
