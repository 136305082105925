import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'

import SlideIn from '../../utils/SlideIn'
import Calendar from './Calendar'
import getWeeksEvents from '../../utils/GetCalendarEvents'
import data from '../../../data'
import '../../../styles/Schedule.scss'

export default function Schedule () {
  const [events, setEvents] = useState([])

  useEffect(() => {
    getWeeksEvents().then((sdata: any) => {
      setEvents(sdata)
    })
  }, [])

  return (
    <Box className="marginContainer" id="schedule" py={8}>
      <SlideIn>
        <Container maxWidth="xl" disableGutters={true}>
          <Typography variant="h4" mb={2}>Schedule</Typography>
          <Calendar data={events} />
          <Box textAlign="right" mt={2}>
            <Link href={data.calendar.publicUrl}>See the Full Schedule</Link>
          </Box>
        </Container>
      </SlideIn>
    </Box>
  )
}
