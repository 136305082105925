import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'

import CenteredHero from '../../CenteredHero'
import data from '../../../data'
import Image2 from '../../../assets/rashguard.jpg'

const Description = `
    There's a lot of killer stuff in the store - hats, rashguards, stickers, t-shirts.  And we all know that there's no better way to go #fullwhitebelt like buying 18 things that say "Jiu-Jitsu" on it.  But seriously, take advantage of the code and get something cool for yourself.`

const ctaURL = `${data.store.url}discount/JUSTJOINED`
export default function Gear () {
  return (
        <CenteredHero
            title="Check the store"
            subtitle="Use code 'JUSTJOINED' to get 20% off of everything in the store."
            image={Image2}
            ctalink={ctaURL}
            cta="Get 20% Off with 'JUSTJOINED'"
        >
            <Typography mb={2} variant="body1" color="secondary">{Description}</Typography>
            <Box mb={3}>
                <Link href={ctaURL} color="secondary.dark">The Black Dog Jiu-Jitsu Co. Store</Link>
            </Box>
        </CenteredHero>
  )
}
