/* eslint-disable */
const content = `
This is where the trail starts.  And to be fair, we know that the path isn't for everyone.  Consensus is generally that around 15% of the people that start Jiu-Jitsu will earn their Blue Belt let alone their Purple Belt, their Brown and finally their Black Belt.  But make no mistake about it, this is where that all begins.

Our goal in the BJJ 101 class is simple:  To get you to the point where you are ready to engage in live rolls (sparring).  In the process, you're going to learn the foundational elements of Jiu-Jitsu - techniques that you will carry with your entire Jiu-Jitsu journey.

The BJJ 101 course is an introductuctory program intended for beginners who wish to gain a practical, fundamental understanding of the sport. Similar in many regards to university coursework, BJJ 101 curriculum commences quarterly and follows a structured curriculum.  Students will learn takedowns, control positions (pins) and submission techniques. We'll spend time looking at the main positions of Brazilian Jiu-Jitsu (the guard, mount, back-mount etc), transitions between the positions and submissions from the positions we cover.

Jiu-Jitsu is an intellectually engaging and physically challenging sport.  You'll get in fighting shape.  You'll forge great friendships.  And you'll walk off the mats ready to handle what life throws at you.

Ask anyone who trains &mdash; they'll all tell you the exact same thing: "I wish I had started sooner."  We hope that you'll heed their advice.

The trail starts here.

`;

export default content
