import * as React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import data from '../../../data'
import Image2 from '../../../assets/rashguard.jpg'

export default function BJJ101 () {
  const backgroundImage = {
    backgroundImage: `url(${Image2})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: '40vh'
  }

  const BJJ101Description = `
    There's a lot of killer stuff in the store - hats, rashguards, stickers, t-shirts.  And we all know that there's no better way to go #fullwhitebelt like buying 18 things that say "Jiu-Jitsu" on it.  But seriously, we'd be stoked to see you in some Black Dog Jiu-Jitsu Company gear.`

  return (
    <Box id="gear" sx={backgroundImage} className="marginContainerTall dimmedImage">
      <Grid container>
        <Grid item md={8}>
          <Typography mb={1} variant="h1" sx={{ fontSize: { xs: '5rem', sm: '6rem' } }}>Check out the store</Typography>
          <Typography mb={4} variant="body1" color="secondary">{BJJ101Description}</Typography>
        </Grid>
        <Grid item md={4}>
          <Box
            style={{ height: '100%' }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Button variant="contained" href={data.store.url} color="secondary">Visit the store</Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
