import * as React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import TwoColumnHero from '../../TwoColumnHero'
import Image2 from '../../../assets/kneeonbelly2.jpg'

export default function Hero () {
  return (
    <TwoColumnHero
      title="Enrollment Complete"
      image={Image2}
    >
      <Box sx={{
        color: '#ffffff'
      }}
      >
        <Typography
          variant="subtitle1"
          mb={1}
          color="secondary"
          sx={{
            fontWeight: 'bold',
            textTransform: 'uppercase'
          }}
        >Welcome to The Black Dog Jiu-Jitsu Co.</Typography>
        <Typography variant="body1" mb={1} color="secondary">Thank you for completing your registration and becoming a member at The Black Dog Jiu-Jitsu Co. &mdash;  we're excited to train with you.  At The Black Dog Jiu-Jitsu Co. our primary objective is to provide you with an ideal training environment and world-class instruction.</Typography>
        <Typography variant="body1" mb={1}color="secondary">You should see a couple of emails in your inbox very shortly: One with details about your credit card transaction and one or more with regards to the documents you signed in the membership process.</Typography>
        <Typography variant="body1" mb={1}color="secondary">Again, welcome to The Black Dog.</Typography>
      </Box>
    </TwoColumnHero>
  )
}
