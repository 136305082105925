import * as React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'

import MailingList from '../../MailingList'
import SocialMedia from '../../SocialMedia'
import AboutFullDesktop from './AboutFullDesktop'
import AboutFullMobile from './AboutFullMobile'

function About () {
  const smallScreen = !useMediaQuery('(min-width:600px)')

  return (
    <div>
      {
        smallScreen
          ? (<AboutFullMobile />)
          : (<AboutFullDesktop />)
      }
      <SocialMedia />
      <MailingList />
    </div>
  )
}

export default About
