import image from '../../../assets/standing1.jpg'

const images = [
  {
    img: image,
    title: 'Coming Soon'
  },
  {
    img: image,
    title: 'Coming Soon'
  },
  {
    img: image,
    title: 'Coming Soon'
  },
  {
    img: image,
    title: 'Coming Soon'
  }
]

export default images
