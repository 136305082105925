/* eslint-disable */
const content = `
No-Gi Jiu-Jitsu - or "Submission Grappling" - is simply Jiu-Jitsu practiced without the Gi. This style of grappling represents the intersection of a variety of different styles of grappling internationally.

No-Gi Jiu-Jitsu has a reputation of being a faster and somewhat more aggressive style that resembles collegiate wrestling in many respects.  Training No-Gi tends to emphasize speed and agility as opposed to size and strength.

Rule sets tend to be more permissive in No-Gi Jiu-Jitsu;  Leg locks - for example - constitute foundational elements of the discipline even at beginner to intermediate levels. At The Black Dog, we practice under the Abu Dhabi Combat Club (ADCC) rule set with particular limitations imposed on beginner to intermediate athletes.

Currently, the best in the world is Gordon Ryan &mdash; who is himself a student of the great John Danaher.  We've included an example of his highlights below:

<div class="fullWidthYouTube">
<iframe
  width="100%"
  height="315"
  src="https://www.youtube.com/embed/uQU6n2YP0_Y"
  title="YouTube video player"
  frameborder="0"
  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
  allowfullscreen>
</iframe>
</div>
`
export default content
