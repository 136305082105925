import React, { useRef } from 'react'
import Box from '@mui/material/Box'
import useMediaQuery from '@mui/material/useMediaQuery'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'

export default function Calendar (props: any) {
  const smallScreen = !useMediaQuery('(min-width:600px)')

  const calendarRef = useRef(null)

  const calendarEvents = props.data.map((raw: any) => ({
    id: raw.id,
    title: raw.summary,
    start: new Date(raw.start.dateTime).toISOString(),
    end: new Date(raw.end.dateTime).toISOString()
  }))

  const smallProps = smallScreen
    ? {
        views: {
          timeGridFourDay: {
            type: 'timeGrid',
            duration: { days: 4 }
          }
        }
      }
    : {}

  return (
    <Box id="newSchedule">
      <FullCalendar
        ref={calendarRef}
        plugins={[timeGridPlugin]}
        headerToolbar={{
          // left: "myCustomButton prev,today,next",
          left: '',
          center: '',
          right: ''
        }}
        initialView={smallScreen ? 'timeGridFourDay' : 'timeGridWeek' }
        {...smallProps}
        duration={{ days: 3 }}
        slotEventOverlap={false}
        allDaySlot={false}
        nowIndicator={true}
        weekends={true}
        slotMinTime="06:00:00"
        slotMaxTime="22:00:00"
        contentHeight="auto"
        events={calendarEvents}
        eventColor="#7e7e7e"
      />
    </Box>
  )
}
