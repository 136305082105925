import React /*, { useRef } */ from 'react'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'

import Gordon from '../../../assets/gordon3.webp'
import Ruotolo from '../../../assets/ruotolo.jpg'
import Maciel from '../../../assets/maciel.jpg'
import SlideIn from '../../utils/SlideIn'
// import Button from '@mui/material/Button'
// import 'add-to-calendar-button/assets/css/atcb.css'
// import { atcb_action } from 'add-to-calendar-button'
// import data from '../../../data'

function NoteworthyAthlete (props: any) {
  return (
    <Box>
      <Grid container>
        <Grid
          item
          xs={12}
          mx={6}
          sx={{
            marginLeft: 0,
            marginRight: 0
          }}
        >
          <Box
            py={8}
            sx={{
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <Box
              className="roundedImageOverlay"
              sx={{
                alignContent: 'center'
              }}
            >
              <img
                src={props.image}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} mx={6} sx={{
          marginLeft: 0,
          marginRight: 0
        }}>
          <Box>
            <Typography variant="h5">{props.athlete}</Typography>
            <Typography
              variant="subtitle1"
              color="primary"
              mb={1}
              sx={{
                textTransform: 'uppercase'
              }}
            >{props.subtitle}</Typography>
            <Typography variant="body1" mb={1}>{props.description}</Typography>
            <Link href={props.link}>Read More</Link>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default function NoteworthyAthletes () {
  return (
    <Box id="noteworthy-athletes" className="white">
      <Box className="marginContainer">
        <Container maxWidth="xl" disableGutters={true}>
          <SlideIn>
            <Grid container>
              <Grid item xs={12}>
                <Box>
                  <Typography variant="h5" sx={{ textTransform: 'uppercase' }}>
                    Noteworthy Youth Athletes
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <NoteworthyAthlete
                  link="https://www.bjjheroes.com/bjj-fighters/gordon-ryan"
                  image={Gordon}
                  athlete="Gordon Ryan"
                  subtitle="New Wave Jiu-Jitsu"
                  description="Widely regarded as the No-Gi GOAT, Gordon Ryan began his rise to the top at the age of 15 years old.  Gordon holds numerous titles but not least of which are his multi-division ADCC championships."
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <NoteworthyAthlete
                  link="https://www.bjjheroes.com/editorial/the-ruotolo-brothers-funky-jiu-jitsu"
                  image={Ruotolo}
                  athlete="Tye & Kade Ruotolo"
                  subtitle="Atos Jiu-Jitsu"
                  description="Twin brothers Kade and Tye Ruotolo became jiu-jitsu's first true 'child star' athletes.  Between the two brothers they hold numerous titles including IBJJF and ADCC Championships."
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <NoteworthyAthlete
                  link="https://www.bjjheroes.com/bjj-fighters/kennedy-maciel"
                  image={Maciel}
                  athlete="Kennedy Maciel"
                  subtitle="Alliance Jiu-Jitsu"
                  description="Kennedy is the son of the legendary Rubens Charles Maciel (Cobrinha).  Despite his noteworthy paternity, Kennedy started jiu-jitsu at 15 and currently holds numerous IBJJF championship wins."
                />
              </Grid>
            </Grid>
          </SlideIn>
        </Container>
      </Box>
    </Box>
  )
}
