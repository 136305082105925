import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'

import CenteredHero from './CenteredHero'
import data from '../data'
import Image2 from '../assets/rashguard.jpg'

export default function StoreAd () {
  return (
        <CenteredHero
            title="Check the store"
            subtitle="The Black Dog Jiu-Jitsu Company Gear Served Fresh Daily"
            image={Image2}
            ctalink={data.store.url}
            cta="Check Out The Store"
        >
            <Typography mb={2} variant="body1" color="secondary">There's a ton of great stuff available in the store.  Hats, t-shirts, rashguards -  all with The Black Dog Jiu-Jitsu Co. brand.  Look rad, train sharp and represent The Black Dog out in the wild.</Typography>
            <Box mb={3}>
                <Link href={`${data.store.url}discount/JUSTJOINED`}color="secondary.dark">The Black Dog Jiu-Jitsu Co. Store</Link>
            </Box>
        </CenteredHero>
  )
}
