import * as React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import Image2 from '../../../assets/kneeonbelly2.jpg'
import TwoColumnHero from '../../TwoColumnHero'

export default function Hero () {
  return (
    <TwoColumnHero
      title="Martial Arts"
      subtitle="And Combat Sport"
      image={Image2}
    >
      <Box
        sx={{
          color: '#ffffff',
          marginTop: '-1em'
        }}
      >
        <Box>
            <Typography>Outisde of the obnoxious cultural associations, we don't mind the term.</Typography>
        </Box>
      </Box>
    </TwoColumnHero>
  )
}
