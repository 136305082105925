/* eslint-disable */
const content = `
Brazilian Jiu-Jitsu practiced in the Gi is perhaps the most traditional style of Brazilian Jiu-Jitsu.  Training in this mode is similar in many respects to Judo, albeit with some notable differences:  Jiu-Jitsu focuses on groundwork whereas Judo empahasizes standing throws.

At The Black Dog we follow a "feet-to-floor" philosophy; Sparring rounds begin on the feet and progress to mat-work after a takedown or guard-pull event occurs.

Training in the Gi yields slower and more controlled movements which may result in a different learning curve relative to other modes of practice. Moreover, the role of gripping and overall physical strength tends to be emphasized in the Gi.

There are so many great champions in the sport, not least of which is Roger Gracie &mdash; who is arguably the greatest of all time.  For us, a more-than-mentionable pair of athletes are the Mendes Brothers out of San Diego, California.  Check out the video below of Gui and Rafa training in the Gi; It's a great example of what Gi Jiu-Jitsu can look like:

<div class="fullWidthYouTube">
<iframe
  width="100%"
  height="315px"
  src="https://www.youtube.com/embed/ivH7ZsWN3O4"
  title="YouTube video player"
  frameBorder="0"
  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
  allowFullScreen
></iframe>
</div>
`
export default content;
