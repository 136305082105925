import React from 'react'

const AltTextSub = (props: any) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg">
        <path d="M118.981 196.765C118.981 216.627 103.024 233.178 79.0872 241.453C71.1083 254.694 63.1295 261.314 45.576 261.314C23.2352 261.314 0.894531 246.418 0.894531 206.696V130.562C8.87338 130.562 15.2564 125.597 20.0437 115.666C26.4268 105.736 37.5972 107.391 37.5972 115.666V200.076C37.5972 234.833 59.938 243.108 85.4703 223.247C104.62 206.696 64.7254 157.043 64.7254 112.356C64.7254 95.8051 67.9168 72.6338 83.8746 52.7727L63.1295 27.9463C59.9379 24.6361 58.3422 22.981 53.5549 31.2564C47.1718 41.187 32.8099 24.6361 56.7465 19.6709C61.5338 8.08522 85.4703 -6.81049 95.0449 4.77514L106.215 19.6709C109.407 22.981 112.598 24.6362 115.79 21.326C118.982 18.0159 123.769 19.6708 123.769 22.981C123.769 26.2912 120.577 24.6362 115.79 29.6015C74.2999 62.7033 118.981 143.803 118.981 196.765Z"/>
        <path d="M136.536 44.4985C131.748 37.8781 123.77 42.8435 123.77 37.8782C123.77 34.568 130.153 34.568 134.94 29.6027C154.089 11.3967 160.472 13.0518 174.834 29.6027C179.621 34.568 181.217 36.2231 181.217 32.9129C184.409 24.6374 193.983 31.2578 186.005 37.8782C160.472 61.0495 150.898 64.3596 144.515 54.4291C146.11 56.0842 136.536 44.4985 136.536 44.4985ZM189.196 241.454C179.621 241.454 178.026 261.315 165.26 261.315C158.876 261.315 144.515 253.04 144.515 210.008V163.665C144.515 145.459 150.898 133.873 160.472 123.943L146.11 94.1512C142.919 90.841 141.323 89.1858 136.536 97.4613C130.153 107.392 115.791 90.841 139.727 85.8757C144.515 74.2901 168.451 57.7393 174.834 70.98L193.983 109.047C181.217 118.978 181.217 133.873 181.217 176.906V208.353C181.217 219.938 184.409 231.524 190.792 233.179C201.962 226.559 205.154 241.454 189.196 241.454Z"/>
        <path d="M323.242 241.453C313.668 241.453 312.072 261.314 299.306 261.314C294.518 261.314 286.54 258.004 281.752 241.453C267.39 243.108 268.986 261.314 253.028 261.314C235.475 261.314 198.772 236.488 214.73 193.456C232.283 147.113 237.071 138.838 213.134 94.1501C211.538 90.8399 208.347 89.1848 203.559 97.4602C197.176 107.391 182.814 90.8399 206.751 85.8746C211.538 74.289 232.283 59.3933 241.858 70.9789C273.773 112.356 254.624 143.803 243.454 175.25C232.283 205.041 257.816 229.867 273.773 229.867L280.157 228.213C278.561 223.247 278.561 216.627 278.561 208.351C278.561 160.354 273.773 162.009 273.773 140.493C273.773 130.562 276.965 120.632 283.348 112.356L272.178 92.4952C270.582 89.185 268.986 89.1849 265.795 90.84C257.816 99.1154 249.837 84.2197 265.795 84.2197C273.773 70.979 294.518 56.083 300.901 69.3237L308.88 82.5645C310.476 85.8747 315.263 87.5299 318.455 84.2197C321.646 80.9095 326.434 82.5645 326.434 85.8746C326.434 89.1848 323.242 89.185 318.455 92.4952C313.668 95.8053 308.88 102.426 308.88 115.666C308.88 140.493 315.263 137.183 315.263 208.351C315.263 219.937 318.455 231.523 324.838 233.178C336.008 228.213 339.2 241.453 323.242 241.453Z"/>
        <path d="M525.903 196.765C525.903 216.626 509.946 233.177 486.009 241.452C478.03 254.693 470.051 261.313 452.498 261.313C430.157 261.313 407.816 246.417 407.816 206.695V130.561C415.795 130.561 422.178 125.596 426.966 115.665C433.349 105.735 444.519 107.39 444.519 115.665V200.075C444.519 234.832 466.86 243.107 492.392 223.246C511.541 206.695 471.647 157.042 471.647 112.355C471.647 95.8041 474.839 72.6328 490.796 52.7717L470.051 27.9453C466.86 24.6351 465.264 22.98 460.477 31.2555C454.094 41.186 439.732 24.6351 463.668 19.6699C468.456 8.08424 492.392 -6.81147 501.967 4.77416L513.137 19.6699C516.329 22.9801 519.52 24.6353 522.712 21.3251C525.903 18.0149 530.691 19.6698 530.691 22.98C530.691 26.2902 527.499 24.6353 522.712 29.6005C481.222 62.7023 525.903 143.802 525.903 196.765Z"/>
        <path d="M543.458 44.4985C538.67 37.8781 530.691 42.8435 530.691 37.8782C530.691 34.568 537.074 34.568 541.862 29.6027C561.011 11.3967 567.394 13.0518 581.756 29.6027C586.543 34.568 588.139 36.2231 588.139 32.9129C591.331 24.6374 600.905 31.2578 592.926 37.8782C567.394 61.0495 557.82 64.3596 551.436 54.4291C551.436 56.0842 543.458 44.4985 543.458 44.4985ZM596.118 241.454C586.543 241.454 584.948 261.315 572.181 261.315C565.798 261.315 551.436 253.04 551.436 210.008V163.665C551.436 145.459 557.82 133.873 567.394 123.943L553.032 94.1512C549.841 90.841 548.245 89.1858 543.458 97.4613C537.075 107.392 522.713 90.841 546.649 85.8757C551.436 74.2901 575.373 57.7393 581.756 70.98L600.905 109.047C588.139 118.978 588.139 133.873 588.139 176.906V208.353C588.139 219.938 591.331 231.524 597.714 233.179C608.884 226.559 612.076 241.454 596.118 241.454Z"/>
        <path d="M714.203 77.597C707.82 94.1479 695.053 102.423 680.691 104.079C688.67 135.525 675.904 147.111 667.925 166.972C656.755 196.764 671.117 226.555 685.479 233.176C696.649 226.555 699.841 241.451 683.883 239.796C674.308 239.796 672.713 259.657 659.946 259.657C653.563 259.657 628.031 248.071 628.031 210.004C628.031 162.007 672.713 153.731 650.372 107.389C648.776 104.078 647.18 100.768 647.18 99.1132L618.456 92.4929C613.669 90.8378 610.478 90.8377 607.286 94.1479C600.903 104.078 586.541 87.5276 610.478 82.5623C624.839 69.3216 629.627 64.3563 639.201 66.0114C639.201 59.391 639.201 52.7707 639.201 46.1503C647.18 46.1503 653.563 41.1849 658.351 31.2544C664.734 21.3238 675.904 22.9789 675.904 31.2544V74.2868C695.053 77.597 712.607 75.9419 717.394 66.0114C718.99 59.391 733.352 69.3215 714.203 77.597Z"/>
        <path d="M726.97 191.8C728.566 185.179 734.949 171.938 741.332 176.904C744.523 178.559 742.928 183.524 738.14 186.834C726.97 196.765 734.949 214.971 744.523 223.246C758.885 236.487 771.651 233.177 784.418 221.591C784.418 191.799 766.864 190.145 739.736 170.283C699.842 140.492 693.459 115.665 720.587 90.839C750.906 61.0474 755.694 59.3923 765.268 74.2881C771.651 84.2187 778.035 92.4942 786.013 95.8043C790.801 97.4594 798.78 94.1492 801.971 90.839C805.163 87.5288 809.95 89.184 809.95 92.4942C809.95 97.4595 800.375 97.4594 797.184 99.1145C787.609 107.39 786.013 122.286 770.056 122.286C763.673 122.286 754.098 117.32 744.523 105.735C738.14 97.4593 733.353 89.184 726.97 95.8043C720.587 102.425 720.587 118.976 765.268 145.457C782.822 155.388 805.163 170.283 808.354 190.144C811.546 210.006 790.801 228.211 773.247 238.142C766.864 248.073 758.885 258.003 742.928 258.003C726.97 258.003 701.438 236.487 701.438 218.281C704.629 206.695 714.204 198.42 726.97 191.8Z"/>
        <path d="M937.613 241.453C928.039 241.453 926.443 261.314 913.677 261.314C908.889 261.314 900.911 258.004 896.123 241.453C881.761 243.108 883.357 261.314 867.399 261.314C849.846 261.314 813.143 236.488 829.101 193.456C846.654 147.113 851.442 138.838 827.505 94.1501C825.909 90.8399 822.718 89.1848 817.931 97.4602C811.547 107.391 797.186 90.8399 821.122 85.8746C825.909 74.289 846.654 59.3933 856.229 70.9789C888.145 112.356 868.995 143.803 857.825 175.25C846.654 205.041 872.187 229.867 888.144 229.867L894.528 228.213C892.932 223.247 892.932 216.627 892.932 208.351C892.932 160.354 888.144 162.009 888.144 140.493C888.144 130.562 891.336 120.632 897.719 112.356L886.549 92.4952C884.953 89.185 883.357 89.1849 880.166 90.84C872.187 99.1154 864.208 84.2197 880.166 84.2197C888.144 70.979 908.89 56.083 915.273 69.3237L923.252 82.5645C924.847 85.8747 929.634 87.5299 932.826 84.2197C936.018 80.9095 940.805 82.5645 940.805 85.8746C940.805 89.1848 937.613 89.185 932.826 92.4952C928.039 95.8053 923.252 102.426 923.252 115.666C923.252 140.493 929.635 137.183 929.635 208.351C929.635 219.937 932.826 231.523 939.209 233.178C950.38 228.213 953.571 241.453 937.613 241.453Z"/>
        <path d="M1031.76 107.391C1014.21 70.9787 1020.59 52.7726 1055.7 22.9809C1086.02 -6.81068 1095.59 -0.190319 1100.38 6.43004C1108.36 16.3606 1111.55 22.981 1121.13 27.9463C1127.51 31.2565 1129.1 26.2911 1132.3 22.9809C1135.49 19.6708 1140.27 21.326 1140.27 24.6361C1140.27 29.6014 1130.7 29.6013 1127.51 31.2564C1117.93 39.5319 1122.72 54.4278 1106.76 54.4278C1100.38 54.4278 1090.81 49.4626 1081.23 37.8769C1074.85 29.6015 1070.06 21.3259 1063.68 27.9463C1046.12 46.1523 1050.91 61.0482 1065.27 87.5296C1079.64 117.321 1073.25 135.527 1063.68 165.319C1057.29 181.87 1055.7 200.076 1063.68 210.006C1078.04 226.557 1100.38 228.212 1111.55 221.592C1119.53 216.627 1127.51 211.661 1130.7 206.696C1137.08 195.11 1148.25 205.041 1137.08 213.316C1125.91 221.592 1121.13 228.212 1117.93 236.488C1109.96 253.039 1098.79 259.659 1082.83 259.659C1066.87 259.659 1025.38 248.073 1025.38 203.386C1022.19 155.388 1060.49 163.664 1031.76 107.391Z"/>
        <path d="M1242.4 155.387C1269.53 203.385 1228.04 239.797 1213.68 239.797C1204.11 239.797 1202.51 259.658 1189.74 259.658C1172.19 259.658 1138.68 234.831 1151.45 191.799C1157.83 171.938 1162.62 160.352 1154.64 148.767C1143.47 133.871 1140.28 117.32 1165.81 90.8385C1196.13 61.0469 1207.3 61.0468 1216.87 69.3223L1236.02 82.5631C1240.81 85.8732 1244 89.1834 1247.19 85.8732C1250.38 82.563 1255.17 84.2182 1255.17 87.5284C1255.17 90.8386 1251.98 89.1834 1247.19 94.1486C1229.64 104.079 1224.85 122.285 1242.4 155.387ZM1213.68 175.248C1204.11 160.352 1194.53 135.526 1223.26 109.045C1210.49 110.7 1202.51 105.734 1191.34 95.8038C1184.96 90.8386 1181.77 90.8386 1176.98 95.8038C1170.6 102.424 1175.38 110.7 1184.96 125.596C1194.53 142.146 1184.96 157.042 1180.17 173.593C1170.59 203.385 1194.53 228.211 1210.49 228.211C1221.66 228.211 1236.02 211.66 1213.68 175.248Z"/>
        <path d="M1258.36 228.212H1288.68V259.659H1258.36V228.212Z"/>
    </svg>
)

export default AltTextSub
