import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'

import SlideIn from '../../utils/SlideIn'

function createData (
  name: string,
  white: boolean,
  blue: boolean,
  purple: boolean,
  brown: boolean,
  black: boolean
) {
  return { name, white, blue, purple, brown, black }
}

const rows = [
  createData('Armbar', true, true, true, true, true),
  createData('Triangle', true, true, true, true, true),
  createData('Shoulder Lock', true, true, true, true, true),
  createData('Omaplata', true, true, true, true, true),
  createData('Guillotine', true, true, true, true, true),
  createData('Rear Naked Choke', true, true, true, true, true),
  createData('Ezekiel Choke', true, true, true, true, true),
  createData('Inverted Shoulder Strangle (D\'Arce/Anaconda)', true, true, true, true, true),
  createData('Bicep Crush', true, true, true, true, true),
  createData('Straight Ankle Lock', true, true, true, true, true),
  createData('Calf Crush', true, true, true, true, true),
  createData('Reaping the leg', false, true, true, true, true),
  createData('Wrist Lock', false, true, true, true, true),
  createData('Toe Hold', false, true, true, true, true),
  createData('Stretching opponents legs apart', false, true, true, true, true),
  createData('Cervical Locks (Twister)', false, true, true, true, true),
  createData('Heel Hook', false, false, true, true, true),
  createData('Jumping Guard', false, false, false, true, true),
  createData('Slam from Guard', false, false, false, false, false),
  createData('Scissors Takedown', false, false, false, false, false),
  createData('Suplex', false, false, false, false, false),
  createData('Spiking Techniques', false, false, false, false, false),
  createData('Can Opener', false, false, false, false, false)
]

function renderCell (value: boolean) {
  return (
    <TableCell
      align="center"
      sx={{
        backgroundColor: setColor(value)
      }}
    >
      {checkOrX(value)}
    </TableCell>
  )
}

function checkOrX (value: boolean) {
  if (value) {
    return (<CheckIcon />)
  }
  return (<CloseIcon />)
}

function setColor (value: boolean) {
  return (value) ? '#4caf50' : '#ef5350'
}

export default function RulesGi () {
  return (
    <Box id="gi" className="marginContainerHorizontal creme">
      <SlideIn>
        <Typography variant="h5" mb={2}>Training in the Gi</Typography>
        <Typography variant="body1" mb={1}>When training with your partner in a Gi, please observe the following rules.  Note that the rule set that you and your partner abide by should be respective to the individual with the lowest rank.  For example, if a Purple Belt and a Blue Belt are rolling togther, the rule set observed is the Blue Belt rule set unless otherwise agreed to by both parties.</Typography>
        <Box py={2}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Technique</TableCell>
                  <TableCell align="right">White Belt</TableCell>
                  <TableCell align="right">Blue Belt</TableCell>
                  <TableCell align="right">Purple Belt</TableCell>
                  <TableCell align="right">Brown Belt</TableCell>
                  <TableCell align="right">Black Belt</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">{row.name}</TableCell>
                    {renderCell(row.white)}
                    {renderCell(row.blue)}
                    {renderCell(row.purple)}
                    {renderCell(row.brown)}
                    {renderCell(row.black)}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </SlideIn>
    </Box>
  )
}
