import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'

export default function CDivider () {
  return (
    <Box px={4} mb={1}>
      <Container maxWidth="xl" disableGutters={true}>
        <Divider textAlign="left">
        <Typography
        variant="subtitle1"
        color="primary"
        sx={{
          fontWeight: 'bold',
          textTransform: 'uppercase'
        }}
        >Other ways to train
        </Typography>
        </Divider>
      </Container>
    </Box>
  )
}
