import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

// import BrandBox from '../../BrandBox'
import EmbedVideo from '../../utils/EmbedVideo'
import videoBg from '../../../assets/hype2.mp4'
import data from '../../../data'

const superContainerPadding = 32
const textPadding = (superContainerPadding - 32)

function HypeText () {
  return (
    <Box id="textContainer" sx={{
      height: '100%',
      maxWidth: '100vw',
      paddingLeft: '32px',
      zIndex: 100,
      position: 'relative',
      paddingTop: '60px',
      paddingBottom: '64px',
      top: `-${textPadding}px`
    }}>
      <Typography
        variant="h2"
        color="secondary"
        mb={2}
        sx={{
          display: 'none',
          lineHeight: 1,
          fontSize: {
            xs: '20px',
            md: '35px'
          }
        }}>
        Our Mandate:
      </Typography>
      {
        data.hypeText.map((ngram: any, index: any) => (
          <Typography
            key={index}
            variant="h1"
            color="secondary.dark"
            sx={{
              textTransform: 'uppercase',
              fontWeight: 'black',
              lineHeight: {
                xs: 1,
                md: 0.85
              },
              fontSize: {
                xs: '40px',
                sm: '50px'
              },
              letterSpacing: {
                xs: '-2px',
                md: '-10px'
              }
            }}>{ngram}</Typography>
        ))
      }
    </Box>
  )
}

export default function SmallHype () {
  return (
    <Box id="smallhype" sx={{
      backgroundColor: '#111111',
      position: 'relative',
      width: '100%',
      height: '100%'
    }}>
      <Box id="videoContainer" sx={{
        position: 'sticky',
        top: '0px',
        height: 'auto',
        width: '100%'
        // paddingBottom: `${superContainerPadding}px`
      }}>
        <EmbedVideo src={videoBg} width="100%"/>
      </Box>
      <HypeText />
    </Box>
  )
}
