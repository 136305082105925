import image from '../../../assets/gym.jpg'

const itemData = [
  {
    img: image,
    title: 'Bed'
  },
  {
    img: image,
    title: 'Books'
  },
  {
    img: image,
    title: 'Sink'
  },
  {
    img: image,
    title: 'Kitchen'
  },
  {
    img: image,
    title: 'Blinds'
  },
  {
    img: image,
    title: 'Chairs'
  },
  {
    img: image,
    title: 'Laptop'
  },
  {
    img: image,
    title: 'Doors'
  },
  {
    img: image,
    title: 'Coffee'
  },
  {
    img: image,
    title: 'Storage'
  },
  {
    img: image,
    title: 'Candle'
  },
  {
    img: image,
    title: 'Coffee table'
  }
]

export default itemData
