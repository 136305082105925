import * as React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
// import Link from '@mui/material/Link'
// import { HashLink } from 'react-router-hash-link'
import Typography from '@mui/material/Typography'

import SlideIn from '../../utils/SlideIn'
import waterBottleImage from '../../../assets/water-bottle.jpg'
import rashGuardImage from '../../../assets/rashguard.jpg'
import towelImage from '../../../assets/towel.jpg'
// import data from '../../../data'

function Item (props: any) {
  return (
    <Paper>
      <Box sx={{ textAlign: 'center', minHeight: '40vh' }} p={3}>
        <Box sx={{
          height: '100%'
        }}>
          <img
            src={props.image}
            width="100%"
            alt={props.imageAltText}
          />
        </Box>
        <Typography variant="subtitle1" color="primary" sx={{ fontWeight: 'bold', textTransform: 'uppercase' }}>{props.title}</Typography>
        <Typography variant="body1" color="primary">{props.subtitle}</Typography>
      </Box>
    </Paper>
  )
}

export default function WhatYouWillNeed () {
  return (
    <Box id="check-the-faqs" className="marginContainerTall creme">
      <SlideIn>
        <Box my={2}>
          <Typography variant="h4" color="primary" mb={1}>What To Bring?</Typography>
          <Typography variant="body1" color="primary">We get this question alot and particularly just before a new class starts.  On your first day of class you wont need a lot of stuff, but here are the most basic items that you'll need to be successful.</Typography>
        </Box>
        <Grid
          container
          spacing={2}
          py={2}
        >
          <Grid item xs={12} md={4}>
            <Item
              title="A rashguard and shorts"
              image={rashGuardImage}
              imageAltText="A Black Dog Rash Guard"
              subtitle="Any athletic wear is fine."
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Item
              title="A water bottle"
              image={waterBottleImage}
              imageAltText="A Black Dog Water Bottle"
              subtitle="Stay hydrated out there."
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Item
              title="A Towel"
              image={towelImage}
              imageAltText="A towel"
              subtitle="Might be a good idea."
            />
          </Grid>
        </Grid>
      </SlideIn>
    </Box>
  )
}
