import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import useMediaQuery from '@mui/material/useMediaQuery'

import locationImages from './shortArchiveImages'
import data from '../../../data'

function LocationImages () {
  const smallScreen = !useMediaQuery('(min-width:900px)')
  return (
    <ImageList
      id="imageSlider"
      gap={1}
      sx={{
        gridAutoFlow: 'column',
        gridTemplateColumns: (smallScreen) ? 'repeat(auto-fit, minmax(80vw, 1fr)) !important' : 'repeat(auto-fit, minmax(30vw, 1fr)) !important',
        gridAutoColumns: (smallScreen) ? 'minmax(80vw, 1fr)' : 'minmax(30vw, 1fr)',
        margin: 0
      }}
    >
      { locationImages.map((item: any, i: number) => (
        <ImageListItem key={i}>
            <Box sx={{
              backgroundImage: `url(${item.img})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              height: (smallScreen) ? '80vw' : '30vw',
              width: '100%'
            }}
            >
            </Box>
        </ImageListItem>
      ))}
      <ImageListItem key="galleryLink">
        <Box className="viewAll gray" sx={{
          // backgroundImage: `url(${wolfWhite})`,
          // backgroundSize: 'cover',
          // backgroundPosition: 'center',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%'
        }}>
          <Link href={data.social.instagramUrl} color="primary"><Typography variant="h5">View All...</Typography></Link>
        </Box>
      </ImageListItem>
    </ImageList>
  )
}

export default function Gallery () {
  return (
    <Box id="gallery" className="creme">
      <LocationImages />
    </Box>
  )
}
