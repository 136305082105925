/* eslint-disable */
import data from '../../../data';

const faq = {
  membership: {
    title: 'Membership',
    id: 'membership',
    questions: [
      {
        question: 'How much does membership cost?',
        answer: `Easy: For most adults it\'s $159.00 a month. For details about the different membership options available, have a look at the [membership options page](${data.memberPortal.options}).

Otherwise, if you're ready to join [Click here](${data.memberPortal.enrollmentUrl}) to sign-up.
        `
      },
      {
        question: 'Is there a membership contract?',
        answer: `There\'s a membership agreement to sign but the terms are super easy -  nothing out of the ordinary.

If you'd like to see the current Membership Agreement [click here](${data.memberPortal.membershipAgreement}).

If you've already enrolled, the membership agreement that you signed should have been emailed to you.  It's also available to you in the [member portal](${data.memberPortal.managementUrl}).`
      },
      {
        question: 'What\'s included in the membership?',
        answer: `Adult members can attend all the weekly programming at the gym - including the BJJ 101 courses, Open Mats etc.  To see this week's schedule, [click here](/#schedule).

We also anticipate holding quarterly social events for members only &mdash; so there's that too.`
      },
      {
        question: 'Can I cross-train while I\'m a member?',
        answer: `Yes.  A membership isn\'t a leash and we've got a lot of great friends in and around the city.  Please do go train and hang out with other gyms &mdash; especially while you travel &mdash; and bring back everything awesome that you learn.`
      },
      {
        question: 'I need a receipt for my last billing.  Where can I get one?',
        answer: `Check the [member portal](${data.memberPortal.managementUrl}) - the link is in the footer of every page on the site.`
      },
      {
        question: 'When will I be billed next?  How can I change the card I have on file? (And other billing questions.)',
        answer: `Check the [member portal](${data.memberPortal.managementUrl}) -  the link is in the footer of every page on the site.`
      },
      {
        question: 'Can I pause my membership?',
        answer: `Absolutely.  If you're injured or need to travel or whatever the circumstances are, we are happy to pause your membership.  The minimum length of time to pause a membership is 1 month.
        Please use the [membership freeze form](${data.memberPortal.freezeForm}).`
      },
      {
        question: 'Does The Black Dog ever offer membership discounts?',
        answer: `In some cases, yes.  In particular, for upper-belts (Brown and Black Belt members) we recognize the value that they add to the gym by offering monthly discounts against their dues.  We also established a "Founders Discount" for individuals who were early to join &mdash; and in very limited quantities.

We reserve the right to use membership discount as incentives to grow programs moving forward, but we are committed to doing so in a very equitable and transparent manner.`
      }
    ]
  },
  bjj101: {
    title: 'BJJ 101',
    id: 'bjj101',
    questions: [
      {
        question: 'Where can I get more information about the BJJ 101 program?',
        answer: `Have you checked out the [BJJ 101 page](/bjj101)?  Maybe start there - and if not there's information listed below here.  If you have additional questions, feel free to send us an email or drop a line.`
      },
      {
        question: 'When does the next BJJ 101 course start?',
        answer: `First, you should read the [BJJ 101 page](/bjj101).

We changed the way that we structure class so that individuals could join the class whenever they're ready to start training.  The course cycles through material quarterly -  and it's not like the content goes out of date.  We encourage you to start attending when you're ready; If you're reading this, that's probably now-ish.`
      },
      {
        question: 'Do I need to "be in shape" to join the BJJ 101 class?',
        answer: `You\'d be surprised how often we get this question.
        
"I\'m going to go to the gym to get ready for Jiu-Jitsu" may sound like a good idea, but it\'s also totally backwards.  "Working out to get ready to work out" is what it amounts to and frankly, it's just another barrier to your success.

We love the expectation that when you commit yourself to something you intend to be good at it.  But there's no advisable course of action - other than jiu-jitsu - if your goal is to get good at jiu-jitsu.`
      },
      {
        question: 'I\'d like to give the BJJ 101 class enrollment as a gift -  is that possible?',
        answer: 'Definitely - come chat us up and we\'ll help you figure that out.'
      },
      {
        question: 'What should I wear (and/or bring) to class?',
        answer: `Athletic attire is great for the BJJ 101 class.  
        
Or - if you're game for it - a [Gi](https://en.wikipedia.org/wiki/Brazilian_jiu-jitsu_gi)!   Either head on down to [a local Gi shop](${data.social.dojoOutfitters}) or buy something convincing on the internet.  Ask us for some help if that's your jam but you're not sure where to start.

A rashguard and a pair of board shorts are next-level appropriate, as well.  Particularly if your interest is primarily No-Gi jiu-jitsu..

We've got stuff like rashguards and shorts for sale in the [store](${data.store.url}) and sometimes at the gym.  Otherwise, a water-bottle and a towel are probably a good ideas.  You shouldn't need anything else.`
      },
      {
        question: 'When is the 101 class?',
        answer: `Mondays and Wednesdays at 6PM, Saturdays at 11AM.
        
The [gym schedule](/#schedule) is on the homepage too, so feel free to refer back to that any time you have questions of the nature.
        `
      },
      {
        question: 'How often should I attend class?',
        answer: `We encourage you to attend as much as you can!  

Training 3x a week puts individuals on a great trajectory to get good at the discipline quickly, but when you're just starting out any level of consistent training will help you improve.`
      },
      {
        question: 'Are classes different -  does the same material get taught throughout the week?',
        answer: `We like to build on the previous class's material, but we also keep material approachable to someone just joining us for the first time that week -  or ever.`
      },
      {
        question: 'How much does it cost?',
        answer: `It's $69 / month to enroll for the full class.

[Click here](${data.memberPortal.bjj101EnrollmentUrl}) to enroll.`
      },
      {
        question: 'Will I get in shape?',
        answer: `We've never seen a way of consistently improving people's physical conditioning like jiu-jitsu.  People regularly lose 10-20 pounds over the course of 6 months.  Don't believe us?  Come try.`
      },
      {
        question: 'How is class structured?',
        answer: `Light warm-ups, technique instruction, partner work (practicing the instruction) and finally some positional practice.  In 1 hour we'll get a strong core workout going and have fun doing it.`
      },
      {
        question: 'What happens if I miss a class?',
        answer: 'Well -  you will miss a class.  But don\'t worry, you can ask your classmates to review what was covered before or after class -  and you can refer back to the syllabus for reference as to what content we covered. And, we also regularly review the last classes material at the beginning of class, too.'
      },
      {
        question: 'Will I get hurt?',
        answer: `Not likely. We've taught these sessions for years and the rate of injury is low.  Major injuries are very rare.

If you want to dig into the theory behind safe training, you should read about [Jigoro Kano's](https://en.wikipedia.org/wiki/Kan%C5%8D_Jigor%C5%8D) Judo philosophy in the early 20th century.

Otherwise, the short answer is probably not - but it is a combat sport so there are some inherent risks.`
      }
    ]
  },
  oneWeekFree: {
    title: 'Trial Membership',
    id: 'trial-membership',
    questions: [
      {
        question: 'Can I try a class or two before signing up?',
        answer: `Absolutely.  No question about it.  We're committed to making sure that there are as few barriers to you training as possible -  trying a couple classes out is just a part of that process!
        
[Sign Up](${data.memberPortal.oneWeekUrl}) for the One Week Limited Membership for just $1 and train at The Black Dog for a full week.
        `
      },
      {
        question: 'How  much does it cost?',
        answer: `Train for a full week for just $1.  You\'ll have access to all of our programming for the week, as well as all of the gym\'s ammenities.

[Click here](${data.memberPortal.oneWeekUrl}) to register.`
      },
      {
        question: 'I\'m travelling and I\'d like to attend a few classes while I\'m in town.  Can I use a trial membership for that?',
        answer: `Absolutely.  We\'re happy to share the mats with you.

[Click here](${data.memberPortal.oneWeekUrl}) to register.`
      },
      {
        question: 'I\'ve used a trial membership before.  Can I buy another?',
        answer: `Sure.  Trial membership is really intentioned at letting people experience the gym - so come talk to us and we\'ll se if there are better arrangements for you. Otherwise, no problem - just register again.

[Click here](${data.memberPortal.oneWeekUrl}) to register.`
      }
    ]
  },
  firstTime: {
    title: 'First Time at The Black Dog Jiu-Jitsu Co.',
    id: 'first-time',
    questions: [
      {
        question: 'What do I wear for class?',
        answer: `For the Gi class, wear a Gi, your belt and a rashguard.

For No Gi - please wear rashguard and shorts.  No shoes, no socks please.

If you're not sure what day it is (Gi or No-Gi) check the [schedule](/#schedule).`
      },
      {
        question: 'What time should I arrive?',
        answer: 'Plan to be about 15 minutes early.  We\'ll confirm that everything is in order and quickly show you around before class.'
      },
      {
        question: 'Where can I park?',
        answer: 'There\'s plenty of parking in front of the building.  And there\'s a ton of parking on the street too.  It shouldn\'t be a concern.'
      },
      {
        question: 'Are there any formalities (bowing etc.) that I should know about?',
        answer: `There's nothing like that.  On people's first day at the gym, we'll typically pair you up with a veteran student to help guide you through class.  Don't worry - we're going to show you the ropes.`
      }
    ]
  },
  gymRules: {
    title: 'Gym Rules & Etiquitte',
    id: 'gym-rules',
    questions: [
      {
        question: 'Where can I find all the gym rules?',
        answer: 'Head over to the [gym rules page](/rules).'
      },
      {
        question: 'Where do I put my shoes and other personal items?',
        answer: 'Shoes go in the cubbies by the door -  please take them off as soon as you enter the gym.  Other personal items (backpacks, laptops, whatever) go in the lockers.'
      },
      {
        question: 'Can I take photographs?',
        answer: 'Photographs are fine - people like to capture the end of a good training session and post that stuff to social media.  We ask that you be respectful with regards to making sure that everyone in the photo is OK with being in the photo.'
      },
      {
        question: 'Can I make a video of class?',
        answer: 'Please refrain from shooting video at The Black Dog unless you\'ve received explicit consent from the instructor.'
      },
      {
        question: 'Can I post my photo or my video to social media (Instagram, Facebook, YouTube etc)?',
        answer: 'Photos are pretty normal - just make sure that the people who are in the photo are OK with you posting to social media.  Videos are a lot more sensitive and we ask that you not post video to social media without explicit consent of the instructor.'
      },
      {
        question: 'What is the rule set for sparring in the Gi?',
        answer: 'Check out the [gym rules page](/rules#gi) for more information.'
      },
      {
        question: 'What is the rule set for sparring during No-Gi sessions?',
        answer: 'Check out the [gym rules page](/rules#nogi) for more information.'
      },
      {
        question: 'I\'ve been sick, but I *think* I\'m feeling better?  Can I come train.',
        answer: 'The rule of thumb here is to stay on the safe side.  Jiu-Jitsu is a close contact and little stuff like common colds can spread fast.  Respect your training partners by staying home when you\'re sick.'
      },
      {
        question: 'I have this weird new rash.  No problem, right?',
        answer: 'Gross.  Go get it checked out.  Lots of skin problems are contagious and start off looking like no big deal.  Please respect your partners and stay off the mats.'
      },
      {
        question: 'How often should I wash my training clothes?',
        answer: 'After every session.'
      },
      {
        question: 'Should I wash my belt?',
        answer: 'Absolutely.'
      },
      {
        question: 'If I\'m late for a class is it cool to join in?',
        answer: `Normally it's fine.  Check with the instructor when you step on the mats and respect activities where your joining may cause another student who may have been on time to sit out for some portion of the activity.  Cardinal rule:  Ask first.`
      },
      {
        question: 'I\'m so hot and sweaty -  it\'s cool if I just take my shirt off, right?',
        answer: `Not really.  Please respect the other members by maintaining a minimum level of civil decorum while at The Black Dog.`
      },
      {
        question: 'There are people in the gym who I prefer not to train with.  Is that cool?',
        answer: `Absolutely.  We ask that you politely decline to train with people who you prefer not to train with.  And if someone has politely declined to train with you, don't take it personally;  Respect their preferences.`
      },
      {
        question: 'Can I wear my training gear with other academy\'s branding on it?',
        answer: `This is a "read betwen the lines" and/or "best to err on the safe side" kind of answer. The honest truth is that if we're friends with the gym your representing, we'll probably be stoked to see it.  Otherwise, if it's discrete, we probably won't care.  But, it's also possible that we ask you to swap it out for the day.`
      },
    ]
  },
  generalQuestions: {
    title: 'General Questions',
    id: 'black-dog',
    questions: [
      {
        question: 'Why is the gym called "The Black Dog"?',
        answer: `The Black Dog is a reference to [Fenrir](https://en.wikipedia.org/wiki/Fenrir) - a character from Norse mythology.  Essentially, he's the looming threat of destruction that hangs over the heads of the Gods.  He resonates with us for a variety of reasons - not least of which is his response to unjust treatment by the Gods and his own eventual revenge.`
      },
      {
        question: 'Who owns The Black Dog?',
        answer: `Tim Dalbey.  You can contact Tim via email at [tim@theblackdogjiujitsu.com](mailto:tim@theblackdogjiujitsu.com).`
      },
      {
        question: 'Who teaches class?',
        answer: `There are a handful of instructors, but our head instructor is Tim Dalbey.  Tim's been training Brazilian Jiu-Jitsu since 1999 and holds the rank of Black Belt.`
      },
      {
        question: 'How do I earn my next belt?',
        answer: `The cardinal rule here is simply not to think of Jiu-Jitsu that way. While we do recognize the accomplishment and skill of our students using standard IBJJF ranking, these decisions are made privately.`
      },
      {
        question: 'Where can I get a Gi (or other Jiu-Jitsu related equipment?)',
        answer: `Make your way over to our [online store](${data.store.url}) first - we've got rashguards and shorts for you to purchase.  We even stock some of that stuff in the gym.

We don't sell Gis at this time, but the good news is that you can easily buy a Gi online or in local retailers like [Dojo Outfitters](https://dojooutfitters.com).  We recommend you start with local retailers as they'll be able to fit you correctly and there's just a lot of cultural value to getting to know the dudes at the local Gi shop.`
      },
      {
        question: `I've got these great protective gloves (or whatever). Can I wear them to class?`,
        answer: 'The short answer is that you probably don\'t need whatever it is you\'re asking about.  In some cases stuff like knee-stabilization devices (etc) are necessary but the overall message is let\'s wear what\'s necessary and keep it as close to that as possible.'
      },
      {
        question: `Can I use grip tape?`,
        answer: 'Yes, you may use grip tape.  Please throw it in the trash when you\'re done though!'
      },
      {
        question: 'Who is The Black Dog affiliated with?',
        answer: `The short answer is that we're totally independent.  We've got alot of friends, but formal affiliations are not really our thing at this time.`
      },
      {
        question: 'Is today Gi or No-Gi?',
        answer: `Check the [schedule](/#schedule).`
      }
    ]
  },
  dropIns: {
    title: 'Drop-Ins',
    id: 'drop-ins',
    questions: [
      {
        question: 'Can I drop-in at The Black Dog?',
        answer: `Absolutely.  But please also bear in mind that The Black Dog reserves the right to refuse service to whomever we please.  That said, we'd love to have you out.

[Click here](${data.memberPortal.dayPassUrl}) to register.`
      },
      {
        question: 'Do I have to sign a waiver?',
        answer: `Yes - it's a part of the drop-in registration process.`
      },
      {
        question: 'Can I drop in on any class?',
        answer: `Yes - but bear in mind that depending on your skill level some classes may be more appropriate than others.  For instance, if you're a veteran on the mats the fundamentals class may not be what you're looking for.  And visa-versa: iIf you're a beginner, the advanced classes will probably be a little more than you care to bite off.`
      },
      {
        question: 'How much is a Drop-In?',
        answer: `Drop-Ins are $20 - that entitles you to all the programming for the day.  We try to keep all payments digital, but if you're in a crunch we'll accept cash.

[Click here](${data.memberPortal.dayPassUrl}) to register.`
      }
    ]
  },
  openMat: {
    title: 'Open Mat',
    id: 'open-mat',
    questions: [
      {
        question: 'Who can attend Open Mat sessions at The Black Dog',
        answer: `Open Mat is intended for everyone (including members from other gyms) to attend.  For those attending who are not members of The Black Dog Jiu-Jitsu Company, we strongly encourage you to maintain a membership with a jiu-jitsu gym, but understand that it is not always possible or easy.

[Click here](${data.memberPortal.openMat}) to register.`
      },
      {
        question: 'Do I have to do sparring rounds during Open Mat?',
        answer: `Not at all - Open Mat is a time to use the mat however you see fit - provided it's Jiu-Jitsu related activity and you're not preventing others from using the mats.`
      },
      {
        question: 'Do I have to sign a waiver?',
        answer: `Yes.  It's a part of the digital check-in process.

[Click here](${data.memberPortal.openMat}) to register.`
      },
      {
        question: 'Do I have to register for Open Mat?',
        answer: `Yes - our digital check-in process is how we keep track of everything.  It's also how you sign the waiver and we've got to make sure we're coving our bases with regards to legal liability.

We really appreciate your patience and cooperation with the process.

[Click here](${data.memberPortal.openMat}) to register.
        `
      },
      {
        question: 'Is Open Mat Gi or No-Gi?',
        answer: `Whatever you're into.`
      }
    ]
  },
  privateLessons: {
    title: 'Private Lessons',
    id: 'private-lessons',
    questions: [
      {
        question: 'Are private lessons available at The Black Dog?',
        answer: `This all depends on the instructor.  In general, instructors have permission to teach private lessons outside of the daily course programming hours at The Black Dog. Keep in mind that each instructor has the right to refuse to provide private lessons to any student.  But we bet if you ask nicely, you can get something set up to help you accelerate your growth.`
      },
      {
        question: 'How much do they cost?',
        answer: `Each instructor sets their own rate.  Speak with the instructor that you're interested in scheduling with to get details.`
      },
      {
        question: 'How long are the private lessons?',
        answer: `Again, the best thing to do is to approach your instructor directly.`
      }
    ]
  },
  youngAdultClasses: {
    title: 'Young Adult Classes',
    id: 'ya',
    questions: [
      {
        question: 'Where do I get more info about the Young Adult classes?',
        answer: `Check out the [Young Adult Page](/ya).  Lot's of good details there about what we're up to.`
      },
      {
        question: 'How old do you have to be to join the Young Adult class?',
        answer: `Athletes must be atleast ${data.ya.age.min} years old.  
        
In exceptional circumstances we can accomodate athletes who are close to ${data.ya.age.min} - but this is absolutely a discretionary call made on a case-by-case basis.`
      },
      {
        question: 'How much is membership for a young adult?',
        answer: `Membership for Young Adults is $129.00 a month, but that price is subject to change from time to time.
        
[Click here to enroll](${data.memberPortal.yaEnrollmentUrl})`
      },
      {
        question: 'When are the Young Adult classes?',
        answer: `First thing: [Check the class schedule](${data.calendar.publicUrl}).  The schedule changes periodically - albeit pretty infrequently - so it doesn't hurt to scan what's posted.  We broadcast any changes to the schedule well in advance, so don't worry too much about surprise changes or whatever. 

Otherwise, our intention is to keep the classes more-or-less just after common afternoon release times from the local high schools.  This gives people a chance to commute from their respective schools to the gym, but not so much time as to keep people waiting around unnecessarily.
        `
      },
      {
        question: 'Can I attend Open Mat?',
        answer: `Yes.  Open Mat is a great time to get some extra, unstructured practice in.  It's also a great time to get some exposure to the larger jiu-jitsu community at The Black Dog.  
        
We do ask that you be on your best behavior around the adult participants though.
        `
      },
      {
        question: `I'm visiting from another school.  Can I drop in?'`,
        answer: `Absolutelty.  Best practice is to check in with us in advance of your visit, where possible.
        
Hit us up at [info@theblackdogjiujitsu.com](mailto:info@theblackdogjiujitsu.com).`
      },
      {
        question: `Can  I compete?`,
        answer: `We totally support athletes competing in appropriate events and brackets.  If you have any questions about what that means for you, come talk to us.`
      },
      {
        question: `I have more questions.  Who should I contact to discuss Young Adult memberships at The Black Dog?`,
        answer: `We get it.  The best way to get ahold of us is by emailing us at [info@theblackdogjiujitsu.com](mailto:info@theblackdogjiujitsu.com).
        
We're happy to chat.  Let us know what's up.`
      },
    ]
  },
  holidaysInclementWeather: {
    title: 'Holidays & Inclement Weather',
    id: 'holidays-inclement-weather',
    questions: [
      {
        question: 'What are the holidays that The Black Dog observes (closes on)?',
        answer: `The Black Dog generally follows Nike's North American holiday schedule. We'll post the days that we intend to be fully closed on the [schedule](/#schedule).

That said, Jiu-Jitsu athletes tend to be pretty Die Hard (heh) so, normally there are informal open mat sessions that are organized in gym channels.  When that happens, pay attention to social media and the schedule section of the site.`
      },
      {
        question: 'Will The Black Dog close for inclement weather?',
        answer: `We're not the Post Office.

If we're closed, it's probably genuinely risky to be commuting or something's happened like we've lost power. Normally these last minute communications happen via social media, so pay extra attention there.`
      }
    ]
  }
}

export default faq
